import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import DataGrid, {
  Column,
  Paging,
  Scrolling,
  SearchPanel,
} from "devextreme-react/data-grid";
import { useSnackbar } from "notistack";
import envConfig from "../../config";
import { getCookie, getHeaders, getLoginSecured } from "../../utils/api";
import CustomTabs from "../../components/Tabs/Tabs";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation, useNavigate } from "react-router-dom";
import useApiCall from "../../hooks/ApiCall";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { createSessionForExternal, formatDate } from "../../utils/common";
import Cookies from "js-cookie";

const ManageSubscriberBusinessFileTab = ({ selectedSubscriber }) => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const [filesData, setFilesData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [menuPosition, setMenuPosition] = useState(null); // To control menu position
  const [selectedFile, setSelectedFile] = useState(null); // The clicked row

  const handleActionMenuOpen = (event, file) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: rect.bottom,
      left: rect.left,
    });
    setSelectedFile(file);
  };

  const handleActionMenuClose = () => {
    setMenuPosition(null);
    setSelectedFile(null);
  };

  const fetchFiles = useCallback(async () => {
    if (!selectedSubscriber?.xxsID) return;
    setLoading(true);
    try {
      const response = await fetch(
        `https://${envConfig.apiDev1}/api/v3/en-au/subscribers/get-list-of-business-files-for-subscriber-min?subscriberId=${selectedSubscriber?.xxsID}&BaseHostURL=${envConfig.apiLogin}`,
        {
          method: "GET",
          headers: getHeaders(),
        }
      );

      const data = await response.json();
      if (data.SuccessYN === "Y") {
        setFilesData(data.ListOfBusinessFiles || []);
        setLoading(false);
      } else {
        setLoading(false);
        enqueueSnackbar(data.ErrorMessage || "Failed to fetch data", {
          variant: "error",
        });
      }
    } catch (error) {
      setLoading(false);
      enqueueSnackbar("Error fetching members data", {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, selectedSubscriber?.xxsID]);

  useEffect(() => {
    fetchFiles();
  }, [fetchFiles, selectedSubscriber]);

  const requestAccess = async (dbid) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://${envConfig.apiDev1}/api/v3/en-au/databases/request-access-to-business-file?BaseHostURL=${envConfig.loginUrl}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...getHeaders(),
            "api-key": "OlivsAuth",
          },
          body: JSON.stringify({
            DatabaseId_xxbId: dbid,
            SubscriberId_xxsId: selectedSubscriber?.xxsID,
            LoginSecuredHash: getLoginSecured(),
          }),
        }
      );

      const data = await response.json();

      if (data.SuccessYN === "Y") {
        enqueueSnackbar("Access requested", {
          variant: "success",
        });
      } else {
        enqueueSnackbar(
          data.ErrorMessage || "Error occurred while processing the request",
          {
            variant: "error",
          }
        );
      }
    } catch (error) {
      enqueueSnackbar(
        error || "Network error or failed to process the request",
        {
          variant: "error",
        }
      );
      console.error("API request failed:", error);
    } finally {
      setLoading(false);
    }
    handleActionMenuClose();
  };

  const deleteBusinessFile = async (dbid) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://${envConfig.apiDev1}/api/v3/en-au/databases/drop-business-file?BaseHostURL=${envConfig.loginUrl}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...getHeaders(),
            "api-key": `${getCookie("apiKey")}-${dbid}`,
          },
          body: JSON.stringify({}),
        }
      );

      const data = await response.json();

      if (data.SuccessYN === "Y") {
        enqueueSnackbar("Database deleted", {
          variant: "success",
        });
        await fetchFiles();
      } else {
        enqueueSnackbar(
          data.ErrorMessage || "Error occurred while processing the request",
          {
            variant: "error",
          }
        );
      }
    } catch (error) {
      enqueueSnackbar(
        error || "Network error or failed to process the request",
        {
          variant: "error",
        }
      );
      console.error("API request failed:", error);
    } finally {
      setLoading(false);
    }
    handleActionMenuClose();
  };

  const enableDisableAutoAddAddOthers = async (addOthers, addNew) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://${envConfig.apiDev1}/api/v3/en-au/subscribers/update-business-files-permissions?BaseHostURL=${envConfig.loginUrl}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...getHeaders(),
            "api-key": getCookie("apiKey") + `-${selectedFile.xxbID}`,
          },
          body: JSON.stringify({
            // SubscriberId: selectedFile.xxbID || selectedSubscriber.xxsID,
            // SubscriberUserId: Number(getCookie("globalUserId")),
            AccessFileAutomaticallyWithAdminPrivilegesYN:
              addNew === "Yes" ? "Y" : "N",
            InviteUsersFromOutsideTeamYN: addOthers === "Yes" ? "Y" : "N",
          }),
        }
      );

      const data = await response.json();

      if (data.SuccessYN === "Y") {
        enqueueSnackbar("Status updated successfully", {
          variant: "success",
        });
        await fetchFiles();
      } else {
        enqueueSnackbar(
          data.ErrorMessage || "Error occurred while processing the request",
          {
            variant: "error",
          }
        );
      }
    } catch (error) {
      enqueueSnackbar(
        error || "Network error or failed to process the request",
        {
          variant: "error",
        }
      );
      console.error("API request failed:", error);
    } finally {
      setLoading(false);
    }
    handleActionMenuClose();
  };

  return (
    <Box>
      <BackdropLoading open={loading} />
      <Box>
        {selectedSubscriber?.xxsID && (
          <>
            <Button
              onClick={() =>
                navigate("/create-new-exacc-file", {
                  state: { redirectBackTo: location.pathname },
                })
              }
              variant="contained"
              color="primary"
              sx={{ mb: 2 }}
            >
              + New Business File
            </Button>

            <DataGrid
              searchExpr={["xxbBusinessName"]}
              dataSource={filesData}
              showBorders={true}
              wordWrapEnabled={true}
              keyExpr="xxbID"
            >
              <Scrolling mode="virtual" />
              <SearchPanel
                visible={true}
                width={240}
                searchExpr={["xxbBusinessName"]}
                placeholder="Search by Business Name"
              />
              {/* First column: Actions */}
              <Column
                caption="Actions"
                width={90}
                alignment="center"
                allowFiltering={false}
                allowSorting={false}
                cellRender={({ data }) => (
                  <IconButton
                    onClick={(event) => handleActionMenuOpen(event, data)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
              />
              <Column dataField="xxbBusinessName" caption="Business Name" />
              <Column
                dataField="xxbTaxCode"
                allowFiltering={false}
                allowHeaderFiltering={false}
                caption="ABN"
              />
              {/* <Column
                dataField="xxbPaidUpToDate"
                caption="Paid Up To Date"
                calculateDisplayValue={(data) =>
                  formatDate(data.xxbPaidUpToDate)
                }
              />
              <Column
                dataField="xxbSubStartDateTime"
                caption="Start Date Time"
                calculateDisplayValue={(data) =>
                  formatDate(data.xxbSubStartDateTime)
                }
              /> */}
              <Column
                allowFiltering={false}
                allowHeaderFiltering={false}
                dataField="xxbCreatedDateTime"
                caption="Created On"
                calculateDisplayValue={(data) =>
                  formatDate(data.xxbCreatedDateTime)
                }
              />
              <Column
                allowFiltering={false}
                allowHeaderFiltering={false}
                dataField="xxuCreatedByFullName"
                caption="Created By"
              />
              <Column
                allowFiltering={false}
                allowHeaderFiltering={false}
                dataField="xxbCanAutoAddYesNo"
                caption="Can Auto Add"
              />
              <Column
                allowFiltering={false}
                allowHeaderFiltering={false}
                dataField="xxbCanAddOthersYesNo"
                caption="Can Add Others"
              />
            </DataGrid>
          </>
        )}
      </Box>

      {selectedFile && (
        <Menu
          open={Boolean(menuPosition)}
          onClose={handleActionMenuClose}
          anchorEl={menuPosition ? document.body : null} // anchorEl to bind to the element
          anchorReference="anchorPosition"
          anchorPosition={
            menuPosition
              ? { top: menuPosition.top, left: menuPosition.left }
              : undefined
          }
        >
          {!selectedFile.xxbAccessStatus ? (
            <MenuItem onClick={() => requestAccess(selectedFile.xxbID)}>
              Request Access
            </MenuItem>
          ) : (
            <MenuItem
              onClick={async () => {
                setLoading(true);
                Cookies.set("databaseId", selectedFile.xxbID);
                await createSessionForExternal(
                  `https://${envConfig.mainServiceUrl}/${selectedFile.xxbID}/en-au/mobile/dashboard`
                );
                setLoading(false);
              }}
            >
              Go to file
            </MenuItem>
          )}
          <MenuItem onClick={() => deleteBusinessFile(selectedFile.xxbID)}>
            Delete Business File
          </MenuItem>
          <MenuItem
            onClick={() =>
              enableDisableAutoAddAddOthers(
                selectedFile.xxbCanAddOthersYesNo,
                selectedFile.xxbCanAutoAddYesNo === "Yes" ? "No" : "Yes"
              )
            }
          >
            {selectedFile?.xxbCanAutoAddYesNo === "Yes"
              ? "Disable Auto Add"
              : "Enable Auto Add"}
          </MenuItem>
          <MenuItem
            onClick={() =>
              enableDisableAutoAddAddOthers(
                selectedFile.xxbCanAddOthersYesNo === "Yes" ? "No" : "Yes",
                selectedFile.xxbCanAutoAddYesNo
              )
            }
          >
            {selectedFile?.xxbCanAddOthersYesNo === "Yes"
              ? "Disable Add Others"
              : "Enable Add Others"}
          </MenuItem>
        </Menu>
      )}
    </Box>
  );
};

export default ManageSubscriberBusinessFileTab;
