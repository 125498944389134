import { usePixel } from "../context/PixelContext";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

export const useTracking = () => {
  const { trackEvent } = usePixel();
  const location = useLocation();

  const trackEventWithUuid = (eventName, params = {}, isCustom = false) => {
    const uuid = localStorage.getItem("uuid");
    trackEvent(eventName, { ...params, id: uuid }, isCustom);
  };

  useEffect(() => {
    trackEventWithUuid("PageView");
  }, [location.pathname]);

  useEffect(() => {
    let uuid = localStorage.getItem("uuid");
    if (!uuid) {
      uuid = uuidv4();
      localStorage.setItem("uuid", uuid);
    }
  }, []);

  return {
    trackLoginSuccess: (email) =>
      trackEventWithUuid("Lead", {
        content_name: "Login Success",
        content_category: "Authentication",
      }),
    trackLoginAttempt: () =>
      trackEventWithUuid(
        "LoginAttempt",
        { content_category: "Authentication" },
        true
      ),
    trackRegistrationStart: () =>
      trackEventWithUuid(
        "RegistrationStart",
        { content_category: "Authentication" },
        true
      ),
    trackRegistrationSuccess: (email) =>
      trackEventWithUuid("CompleteRegistration", {
        content_name: "Registration Success",
        content_category: "Authentication",
      }),
    trackButtonClick: (buttonName) =>
      trackEventWithUuid(
        "ButtonClick",
        { content_name: buttonName, content_category: "Interaction" },
        true
      ),
  };
};
