// import { ReactComponent as EmptyFolderIcon } from "../../assets/dashboard-empty-file.svg";
import { ReactComponent as EmptyFolderIcon } from "../../assets/empty-dashboard.svg";
import {
  Container,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  Button,
  Typography,
  useMediaQuery,
  Menu,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useCallback, useEffect, useRef, useState } from "react";
import OurDictionary from "../../components/OurDictionary/OurDictionary";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTheme } from "../../context/ThemeContext";
import Cookies from "js-cookie";
import { useLoginPopup } from "../../context/LoginPopupContext";
import { enqueueSnackbar, useSnackbar } from "notistack";
import { PageTitle } from "../../pages/CreateBusinessAccount/CreateBusinessAccount";
import { getCookie, getHeaders, getLoginSecured } from "../../utils/api";
import BackdropLoading from "../BackdropLoading/BackdropLoading";
import { createSessionForExternal } from "../../utils/common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import envConfig from "../../config";
import { darkTheme, lightTheme } from "../../utils/themes/themes";

const menuItemsMain = [
  { text: "Open in 2nd instance (s2.exacc.au)", instanceUrl: "s2.exacc.au" },
  { text: "Open in 3rd instance (s3.exacc.au)", instanceUrl: "s3.exacc.au" },
  { text: "Open in 4th instance (s4.exacc.au)", instanceUrl: "s4.exacc.au" },
];

const menuItemsTestEnv = [
  { text: "Open in 2nd instance (s2.olivs.app)", instanceUrl: "s2.olivs.app" },
  { text: "Open in 3rd instance (s3.olivs.app)", instanceUrl: "s3.olivs.app" },
  { text: "Open in 4th instance (s4.olivs.app)", instanceUrl: "s4.olivs.app" },
];

const menuItemsStageEnv = [
  { text: "Open in 2nd instance (s2.ssx.au)", instanceUrl: "s2.ssx.au" },
  { text: "Open in 3rd instance (s3.ssx.au)", instanceUrl: "s3.ssx.au" },
  { text: "Open in 4th instance (s4.ssx.au)", instanceUrl: "s4.ssx.au" },
];

const EmptyDashboard = () => {
  const navigate = useNavigate();

  const { isDarkMode } = useTheme();
  const location = useLocation();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const [loading, setLoading] = useState(false);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
        pt: 5,
        pb: 22.25,
        textAlign: "center",
        px: 5,
      }}
    >
      <Box
        sx={{
          width: {
            xs: "300px", // Width for extra small screens
            sm: "500px", // Width for small screens
            md: "600px", // Width for medium screens
            lg: "650px", // Width for large screens
            xl: "650px", // Width for extra large screens
          },
          marginTop: {
            xs: "-320px", // Margin top for extra small screens
            sm: "-250px", // Margin top for small screens
            md: "-250px", // Margin top for medium screens
            lg: "-230px", // Margin top for large screens
            xl: "-250px", // Margin top for extra large screens
          },
          mb: {
            lg: 0,
            md: -8,
            sm: -15,
            xs: -30,
          },
        }}
      >
        <EmptyFolderIcon width="100%" />
      </Box>
      <Typography
        sx={{
          color: theme.palette.text.main,
          mb: 4,
          fontWeight: 300,
          letterSpacing: "-0.176px",
        }}
      >
        If you are awaiting an invitation to the subscriber’s space, please
        contact the owners and make sure invitation was sent to{" "}
        {getCookie("loginEmail")}.{" "}
      </Typography>
      <Typography
        sx={{
          color: theme.palette.text.main,
          fontWeight: 300,
          letterSpacing: "-0.176px",
        }}
      >
        If you are starting with Exacc on your own, hit the button below. No
        payment details required.
      </Typography>
      <Box sx={{ mt: 5, display: "flex", alignItems: "center" }}>
        <Button
          onClick={async () => {
            setLoading(true);
            navigate("/create-new-subscriber");

            setLoading(true);
          }}
          sx={{ mr: 1 }}
          disabled={loading}
          disableRipple
          type="submit"
          variant="contained"
          color="primary"
        >
          Start my Exacc Business Experience
        </Button>
        <Button
          disabled={loading}
          disableRipple
          variant="contained"
          color="primary"
          sx={{ ml: 1 }}
          onClick={() => {
            navigate("/create-new-exacc-file", {
              state: { redirectBackTo: location.pathname, isDemo: true },
            });
          }}
        >
          Create Demo Business
        </Button>
      </Box>
    </Box>
  );
};

const AccessRequestsDashboard = ({
  requestedAccess,
  refetchInvitations,
  fetchBusinessFiles,
}) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const secondBreakpoint = useMediaQuery("(max-width: 890px)");

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box>
      <OurDictionary isOpen={isOpen} setIsOpen={setIsOpen} />
      <Box
        sx={{
          p: 4,
          borderRadius: "8px",
          boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 400,
          }}
        >
          Pending Requests
        </Typography>
        {requestedAccess?.map((request, index) => (
          <>
            <DashboardRequest
              details={{
                request_name: request.RequestName,
                business_id: request.xxbID,
                global_user_id: request.xxuID,
              }}
              refetchInvitations={refetchInvitations}
              fetchBusinessFiles={fetchBusinessFiles}
            />
            {requestedAccess.length !== index + 1 && (
              <Box
                sx={{
                  borderBottom: "1px solid var(--divider, #EDEDED)",
                }}
              />
            )}
          </>
        ))}
      </Box>
    </Box>
  );
};

const InvitationsDashboard = ({
  isOpen,
  setIsOpen,
  invitations,
  refetchInvitations,
  fetchBusinessFiles,
}) => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  return (
    <Box>
      <OurDictionary isOpen={isOpen} setIsOpen={setIsOpen} />
      <Box
        sx={{
          p: 4,
          borderRadius: "8px",
          boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
        }}
      >
        <Typography
          sx={{
            fontSize: "24px",
            fontWeight: 400,
          }}
        >
          Invitations
        </Typography>
        {invitations?.map((invitation, index) => (
          <>
            <DashboardInvitation
              details={{
                account_type:
                  invitation.userOrSubscriberInvitationType_US === "U"
                    ? "business"
                    : "subscriber",
                invitation_name: invitation.Name,
                sent_by: invitation.userInvitedByFullName,
                id: invitation.Id,
              }}
              refetchInvitations={refetchInvitations}
              fetchBusinessFiles={fetchBusinessFiles}
            />
            {invitations.length !== index + 1 && (
              <Box
                sx={{
                  borderBottom: "1px solid var(--divider, #EDEDED)",
                }}
              />
            )}
          </>
        ))}
      </Box>
    </Box>
  );
};

const DashboardRequest = ({
  details,
  refetchInvitations,
  fetchBusinessFiles,
}) => {
  const secondBreakpoint = useMediaQuery("(max-width: 890px)");
  const { enqueueSnackbar } = useSnackbar();
  const [anchorEl, setAnchorEl] = useState(null);
  const { isDarkMode } = useTheme();
  const { handleOpen } = useLoginPopup();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const handleAcceptOrDeny = async (optionSelected) => {
    const url = `https://${envConfig.apiDev1}/api/v3/en-au/databases/access-request-confirmation-by-user?BaseHostURL=${envConfig.apiLogin}`;
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "olivs-api-real-ip": "31.42.0.186",
      "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
      "session-id": getCookie("sessionId"),
      "browser-agent": window.navigator.userAgent,
      "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
      "api-key": `${getCookie("apiKey")}-0`,
    };
    const body = {
      BusinessFileId_xxbID: details.business_id,
      GlobalUserId_xxuID: details.global_user_id,
      optionSelected: optionSelected,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();

      if (result?.SuccessYN === "Y") {
        enqueueSnackbar(
          optionSelected === 1
            ? "Invitation accepted successfully"
            : "Permissions managed successfully",
          { variant: "success" }
        );
        refetchInvitations();
        await fetchBusinessFiles();
      } else {
        enqueueSnackbar(
          typeof result === "string" ? result : result.ErrorMessage,
          { variant: "error" }
        );
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again.", {
        variant: "error",
      });
      handleOpen();
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (optionSelected) => {
    handleAcceptOrDeny(optionSelected);
    handleClose();
  };

  return (
    <Box sx={{ py: 2, display: "flex", justifyContent: "space-between" }}>
      <Typography
        sx={{
          mt: 0,
          mb: 1,
          fontSize: "20px",
          fontWeight: 400,
          lineHeight: "166%",
          letterSpacing: "0.4px",
        }}
      >
        {details.request_name}
      </Typography>
      <Box
        sx={{
          textAlign: secondBreakpoint ? "right" : "unset",
          mt: secondBreakpoint ? 1 : 0,
        }}
      >
        <Button
          sx={{
            background: "transparent",
            mr: "9px",
          }}
          disableRipple
          variant="outlined"
          onClick={() => handleAcceptOrDeny(3)}
        >
          Decline
        </Button>
        <Button
          onClick={handleClick}
          disableRipple
          variant="contained"
          color="primary"
          endIcon={<ExpandMoreIcon />}
        >
          Accept
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => handleMenuItemClick(1)}>
            Accept with my permissions
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick(2)}>
            Accept and manage permissions
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export const checkAndUpdateApiKeyExpiration = () => {
  const apikeyexpirationdate = getCookie("apiKeyExpirationUTCDate");

  const expirationDate = new Date(apikeyexpirationdate + "Z");
  const now = new Date();

  const timeDifference = expirationDate.getTime() - now.getTime();
  // const fifteenMinutes = 15 * 60 * 1000;
  const prolongByShort = envConfig.loginUrl.includes(".app")
    ? 2 * 60 * 1000
    : 15 * 60 * 1000;

  const expiryPeriodLong = 3 * 24 * 60 * 60 * 1000; // 3 days

  let newExpirationDate;
  if (timeDifference <= prolongByShort) {
    newExpirationDate = new Date(now.getTime() + prolongByShort);
  } else {
    newExpirationDate = new Date(now.getTime() + expiryPeriodLong);
  }

  Cookies.set(
    "apiKeyExpirationUTCDate",
    newExpirationDate.toISOString().split(".")[0],
    {
      path: "/",
    }
  );
};

const DashboardInvitation = ({
  details,
  refetchInvitations,
  fetchBusinessFiles,
}) => {
  const secondBreakpoint = useMediaQuery("(max-width: 890px)");
  const { enqueueSnackbar } = useSnackbar();
  const { handleOpen } = useLoginPopup();

  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const handleAcceptOrDeny = async (isAccept) => {
    const url =
      details.account_type === "subscriber"
        ? `https://${envConfig.apiDev1}/api/v3/en-au/databases/accept-or-deny-subscriber-pending-invitation?BaseHostURL=${envConfig.loginUrl}`
        : `https://${envConfig.apiDev1}/api/v4/en-au/databases/accept-or-deny-business-file-pending-invitation-async?BaseHostURL=${envConfig.loginUrl}`;
    const body =
      details.account_type === "subscriber"
        ? {
            AcceptAOrDenyN: isAccept ? "A" : "N",
            SubscriberId: details.id,
          }
        : { AcceptAOrDenyD: isAccept ? "A" : "D", DatabaseId: details.id };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "olivs-api-real-ip": "31.42.0.186",
          "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
          "session-id": getCookie("sessionId"),
          "browser-agent": window.navigator.userAgent,
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          "api-key": getCookie("apiKey") + "-0",
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      console.log("API response data:", result);
      if (result?.SuccessYN === "Y") {
        enqueueSnackbar(
          isAccept
            ? "Invitation accepted successfully"
            : "Invitation declined successfully",
          { variant: "success" }
        );
        checkAndUpdateApiKeyExpiration();
        refetchInvitations();
        await fetchBusinessFiles();
      } else {
        enqueueSnackbar(
          typeof result === "string" ? result : result.ErrorMessage,
          { variant: "error" }
        );
      }
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again.", {
        variant: "error",
      });
      handleOpen();
    }
  };

  return (
    <Box sx={{ py: 2 }}>
      <Typography
        sx={{
          color: theme.typography.linkColor,
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "266%",
          letterSpacing: "1px",
          textTransform: "uppercase",
          mt: 0,
          mb: 2,
        }}
      >
        {details.account_type === "subscriber"
          ? "SUBSCRIBER ACCOUNT INVITATION"
          : "BUSINESS ACCOUNT INVITATION"}
      </Typography>
      <Typography
        sx={{
          mt: 0,
          mb: 1,
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "166%",
          letterSpacing: "0.4px",
        }}
      >
        {details.account_type === "subscriber"
          ? "You’ve been invited to join Subscriber Account. This membership will allow you to access and manage businesses linked to this account."
          : `You’ve been invited to join Business Account. You’ll get access to your business account shared with ${details.invitation_name} Subscriber Account.`}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: secondBreakpoint ? "column" : "row",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: secondBreakpoint ? "column" : "row",
          }}
        >
          <Typography
            sx={{
              color: theme.palette.text.main,
              fontSize: "16px",
              fontWeight: 500,
              lineHeight: "24px",
              letterSpacing: "0.15px",
              my: 0,
              mr: 2,
            }}
          >
            {details.invitation_name}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.text.disabled,
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              letterSpacing: "0.15px",
            }}
          >{`Invitation sent by ${details.sent_by}`}</Typography>
        </Box>
        <Box
          sx={{
            textAlign: secondBreakpoint ? "right" : "unset",
            mt: secondBreakpoint ? 1 : 0,
          }}
        >
          <Button
            sx={{
              background: "transparent",
              mr: "9px",
            }}
            disableRipple
            variant="outlined"
            onClick={() => handleAcceptOrDeny(false)}
          >
            Decline
          </Button>
          <Button
            onClick={() => handleAcceptOrDeny(true)}
            disableRipple
            variant="contained"
            color="primary"
          >
            Accept
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const BusinessFilesDashboard = ({
  files,
  fullwidth,
  handleDbSelect,
  subscriberTextInfo,
  handleFavChanged,
}) => {
  const location = useLocation();
  const { handleOpen } = useLoginPopup();
  const firstBreakpoint = useMediaQuery("(max-width: 1100px)");
  const secondBreakpoint = useMediaQuery("(max-width: 890px)");
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOption, setSortOption] = useState(() => getCookie("sortBy") || "");
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleContextMenu = (event, business) => {
    event.stopPropagation();
    event.preventDefault();
    setSelectedBusiness(business);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = async (instanceUrl) => {
    if (selectedBusiness) {
      Cookies.set("databaseId", selectedBusiness.xxbID);
      const result = await createSessionForExternal(
        `https://${instanceUrl}/${selectedBusiness.xxbID}/en-au/mobile/dashboard`
      );
      console.log(result);
      if (!result) {
        handleOpen();
      }
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedBusiness(null);
  };

  const filteredAndSortedBusinesses = () => {
    let filteredBusinesses = files?.filter(
      (business) =>
        business.xxbBusinessName
          .toLowerCase()
          .includes(searchTerm.toLowerCase()) ||
        business.xxbID.toString().includes(searchTerm)
    );

    if (sortOption === "mostRecent") {
      filteredBusinesses.sort(
        (a, b) =>
          new Date(b.ssnLastConnnectedDate) - new Date(a.ssnLastConnnectedDate)
      );
    } else if (sortOption === "oldest") {
      filteredBusinesses.sort(
        (a, b) =>
          new Date(a.ssnLastConnnectedDate) - new Date(b.ssnLastConnnectedDate)
      );
    }

    return filteredBusinesses;
  };

  const handleFavoriteToggle = async (business, newValue) => {
    const favoriteData = {
      DatabaseId: business.xxbID,
      FavouriteYN: newValue,
    };
    const url = `https://${envConfig.apiDev1}/api/v3/en-au/databases/manage-user-favourite-databases?BaseHostURL=${envConfig.loginUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(favoriteData),
    });
    const responseData = await response.json();
    if (!response.ok) {
      enqueueSnackbar({
        message: responseData?.ErrorMessage || responseData,
        variant: "error",
      });
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    if (responseData.SuccesYN === "N") {
      enqueueSnackbar({
        message: responseData?.ErrorMessage || responseData,
        variant: "error",
      });
    } else if (responseData.SuccesYN === "Y") {
      await handleFavChanged();
    }
    handleClose();
  };

  return (
    <Container
      sx={{
        marginTop: 5,
        p: 1,
        pb: 3,
        position: "relative",
        boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "24px",
          width: secondBreakpoint ? "206px" : "unset",
          right: fullwidth
            ? "24px"
            : secondBreakpoint
            ? 0
            : firstBreakpoint
            ? "unset"
            : "32px",
          textAlign: secondBreakpoint ? "right" : "unset",
          width: secondBreakpoint ? "206px" : "unset",
        }}
      >
        <Button
          sx={{
            mr: secondBreakpoint ? 0 : 2,
            mb: secondBreakpoint ? 1 : 0,
          }}
          disableRipple
          variant="outlined"
          onClick={() => {
            navigate("/create-new-exacc-file", {
              state: { redirectBackTo: location.pathname, isDemo: true },
            });
          }}
        >
          Create Demo Business
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{
            width: secondBreakpoint ? "190px" : "unset",
            justifyContent: secondBreakpoint ? "center" : "unset",
          }}
          onClick={() => {
            navigate("/create-new-exacc-file", {
              state: { redirectBackTo: location.pathname },
            });
          }}
        >
          {`+   Add New Business`}
        </Button>
      </Box>
      <Typography
        sx={{
          fontSize: "24px",
          mt: 3,
          mb: 3,
          fontWeight: 400,
          lineHeight: "150%",
          letterSpacing: "-0.264px",
          mt: secondBreakpoint ? "100px" : firstBreakpoint ? "64px" : "24px",
        }}
      >
        Select business file
      </Typography>
      {files.length >= 10 && (
        <Box sx={{ display: "flex" }}>
          <TextField
            sx={{
              maxWidth: "240px",
              mr: 2,
            }}
            size="small"
            fullWidth
            label="Search"
            placeholder="Search..."
            variant="outlined"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel sx={{ fontSize: "16px" }}>Sort By</InputLabel>
            <Select
              size="small"
              sx={{ fontSize: "16px", maxWidth: "180px" }}
              value={sortOption}
              onChange={(e) => {
                const selectedValue = e.target.value;
                setSortOption(selectedValue);
                Cookies.set("sortBy", selectedValue);
              }}
              label="Sort By"
            >
              <MenuItem value="">A-Z</MenuItem>
              <MenuItem value="mostRecent">Most Recent</MenuItem>
              <MenuItem value="oldest">Oldest</MenuItem>
            </Select>
          </FormControl>
        </Box>
      )}

      <Grid
        sx={{ mt: 1, display: "flex", flexDirection: "column" }}
        container
        spacing="12px"
      >
        {subscriberTextInfo && (
          <a
            style={{
              color: theme.palette.primary.main,
              marginTop: "8px",
              cursor: "pointer",
              fontSize: "14px",
              fontWeight: 700,
              letterSpacing: "0.4px",
              textTransform: "capitalize",
              textDecoration: "none",
            }}
            onClick={async (e) => {
              e.preventDefault();
              const result = await createSessionForExternal(
                `https://${envConfig.correspondingService}/0/en-au/subscribers/management`
              );
              console.log(result);
              if (!result) {
                handleOpen();
              }
            }}
            href={`https://${envConfig.correspondingService}/0/en-au/subscribers/management`}
          >
            {subscriberTextInfo}
          </a>
        )}
        <div>
          {filteredAndSortedBusinesses().map((business, index) => (
            <Grid
              item
              key={index}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                paddingLeft: "12px",
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
              onClick={() => handleDbSelect(business.xxbID)}
            >
              <IconButton
                aria-label="more"
                aria-controls={`long-menu-${index}`}
                aria-haspopup="true"
                onClick={(event) => handleContextMenu(event, business)}
              >
                <MoreVertIcon />
              </IconButton>
              <Typography
                variant="h6"
                sx={{
                  mx: 2,
                  fontSize: "16px",
                  fontWeight: 500,
                }}
              >
                {business.xxbBusinessName}
              </Typography>
            </Grid>
          ))}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              onClick={() =>
                handleFavoriteToggle(
                  selectedBusiness,
                  selectedBusiness.xxlFavouriteFileYN === "Y" ? "N" : "Y"
                )
              }
            >
              {selectedBusiness?.xxlFavouriteFileYN === "Y"
                ? "Remove from favorites"
                : "Add to favorites"}
            </MenuItem>
            {(envConfig.loginUrl === "my.exacc.au"
              ? menuItemsMain
              : envConfig.loginUrl === "my.ssx.au"
              ? menuItemsStageEnv
              : menuItemsTestEnv
            ).map((item, index) => (
              <MenuItem
                key={index}
                onClick={() => handleMenuItemClick(item.instanceUrl)}
              >
                {item.text}
              </MenuItem>
            ))}
          </Menu>
        </div>
      </Grid>
    </Container>
  );
};

const FavoritesDashboard = ({
  favorites = [],
  fullwidth,
  handleDbSelect,
  handleFavChanged,
}) => {
  const { handleOpen } = useLoginPopup();
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const handleContextMenu = (event, business) => {
    event.preventDefault();
    setSelectedBusiness(business);
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = async (instanceUrl) => {
    if (selectedBusiness) {
      Cookies.set("databaseId", selectedBusiness.xxbID);
      const result = await createSessionForExternal(
        `https://${instanceUrl}/${selectedBusiness.xxbID}/en-au/mobile/dashboard`
      );
      console.log(result);
      if (!result) {
        handleOpen();
      }
    }
    handleClose();
  };

  const handleFavoriteToggle = async (business, newValue) => {
    const favoriteData = {
      DatabaseId: business.xxbID,
      FavouriteYN: newValue,
    };
    const url = `https://${envConfig.apiDev1}/api/v3/en-au/databases/manage-user-favourite-databases?BaseHostURL=${envConfig.loginUrl}`;
    const response = await fetch(url, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(favoriteData),
    });
    const responseData = await response.json();
    if (!response.ok) {
      enqueueSnackbar({
        message: responseData?.ErrorMessage || responseData,
        variant: "error",
      });
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    if (responseData.SuccesYN === "N") {
      enqueueSnackbar({
        message: responseData?.ErrorMessage || responseData,
        variant: "error",
      });
    } else if (responseData.SuccesYN === "Y") {
      await handleFavChanged();
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedBusiness(null);
  };

  return (
    <Container
      sx={{
        marginTop: 5,
        p: 1,
        pb: 3,
        position: "relative",
        boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
        maxWidth: fullwidth ? "unset" : "416px",
        ml: fullwidth ? 0 : "16px",
      }}
    >
      <Typography
        sx={{
          fontSize: "24px",
          mt: 3,
          fontWeight: 400,
          lineHeight: "150%",
          letterSpacing: "-0.264px",
        }}
      >
        Favorites
      </Typography>
      <Grid
        sx={{ mt: 1, display: "flex", flexDirection: "column" }}
        container
        spacing="12px"
      >
        {favorites.map((business, index) => (
          <Grid
            item
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              "&:hover": {
                color: theme.palette.primary.main,
              },
            }}
            onClick={() => {
              handleDbSelect(business.xxbID);
            }}
            onContextMenu={(event) => handleContextMenu(event, business)}
          >
            <IconButton
              aria-label="more"
              aria-controls={`long-menu-${index}`}
              aria-haspopup="true"
              onClick={(event) => {
                event.stopPropagation();
                handleContextMenu(event, business);
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                mx: 2,
                fontSize: "16px",
                fontWeight: 500,
              }}
            >
              {business.xxbBusinessName}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() =>
            handleFavoriteToggle(
              selectedBusiness,
              selectedBusiness.xxlFavouriteFileYN === "Y" ? "N" : "Y"
            )
          }
        >
          {selectedBusiness?.xxlFavouriteFileYN === "N"
            ? "Add to favorites"
            : "Remove from favorites"}
        </MenuItem>
        {(envConfig.loginUrl === "my.exacc.au"
          ? menuItemsMain
          : envConfig.loginUrl === "my.ssx.au"
          ? menuItemsStageEnv
          : menuItemsTestEnv
        ).map((item, index) => (
          <MenuItem
            key={index}
            onClick={() => handleMenuItemClick(item.instanceUrl)}
          >
            {item.text}
          </MenuItem>
        ))}
      </Menu>
    </Container>
  );
};

const Dashboard = () => {
  const secondBreakpoint = useMediaQuery("(max-width: 875px)");
  const navigate = useNavigate();
  const { dbId, lang } = useParams();
  const prefix = `/${dbId}/${lang}/`;

  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const [isOpen, setIsOpen] = useState(false);

  const { handleOpen } = useLoginPopup();
  const headers = getHeaders();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [invitationsData, setInvitationsData] = useState(null);
  const [invitationsLoading, setInvitationsLoading] = useState(true);
  const [invitationsError, setInvitationsError] = useState(null);

  const [businessFilesOfUserData, setBusinessFilesOfUserData] = useState(null);
  const [businessFilesOfUserError, setBusinessFilesOfUserError] =
    useState(null);
  const [businessFilesOfUserLoading, setBusinessFilesOfUserLoading] =
    useState(true);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const handleDialogSubmit = async (secretKey, userCode) => {
    const response = await fetch(
      `https://${envConfig.apiDev1}/api/v3/en-au/user2fa/enable-2fa-security?BaseHostURL=${envConfig.loginUrl}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "olivs-api-real-ip": "31.42.0.186",
          "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
          "session-id": getCookie("sessionId"),
          "browser-agent": window.navigator.userAgent,
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          "api-key": getCookie("apiKey") + "-0",
        },
        body: JSON.stringify({
          Secret2FAKey: secretKey,
          User2FACode: userCode,
        }),
      }
    );

    const result = await response.json();
    console.log(result);

    handleDialogClose();
  };

  const fetchInvitations = async () => {
    setInvitationsLoading(true);
    try {
      const response = await fetch(
        `https://${envConfig.apiDev1}/api/v3/en-au/databases/get-pending-invitations-with-access-requests?BaseHostURL=${envConfig.apiLogin}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "olivs-api-real-ip": "31.42.0.186",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "session-id": getCookie("sessionId"),
            "browser-agent": window.navigator.userAgent,
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "api-key": getCookie("apiKey") + "-0",
          },
          body: null,
          navigate,
          handleOpen,
        }
      );
      const data = await response.json();
      setInvitationsData(data);
    } catch (error) {
      setInvitationsError(error);
    } finally {
      setInvitationsLoading(false);
    }
  };

  const fetchBusinessFiles = useCallback(async () => {
    setBusinessFilesOfUserLoading(true);
    try {
      const response = await fetch(
        `https://${envConfig.apiDev1}/api/v4/en-au/databases/get-user-databases-async?BaseHostURL=${envConfig.apiLogin}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "olivs-api-real-ip": "31.42.0.186",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "session-id": getCookie("sessionId"),
            "browser-agent": window.navigator.userAgent,
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "api-key": getCookie("apiKey") + "-0",
          },
          body: JSON.stringify({}),
        }
      );

      const result = await response.json();
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      setBusinessFilesOfUserData(result);
    } catch (error) {
      setBusinessFilesOfUserError(error);
    } finally {
      setBusinessFilesOfUserLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchInvitations();
    fetchBusinessFiles();
  }, []);

  const invitations = invitationsData?.ListOfInvitations;
  const requestedAccess = invitationsData?.ListOfAccessRequests;

  const firstName = getCookie("firstName");

  const favorites = businessFilesOfUserData?.filter(
    (business) => business?.xxlFavouriteBF === "Y"
  );

  const handleDbSelect = async (dbId) => {
    Cookies.set("databaseId", dbId);
    const result = await createSessionForExternal(
      `https://${envConfig.mainServiceUrl}/${dbId}/en-au/mobile/dashboard`
    );
    console.log(result);
    if (!result) {
      handleOpen();
    }
  };

  if (invitationsLoading) {
    return (
      <BackdropLoading
        open={invitationsLoading || businessFilesOfUserLoading}
      />
    );
  }

  return (
    <>
      <BackdropLoading
        open={invitationsLoading || businessFilesOfUserLoading}
      />
      <PageTitle
        title={`Welcome to Exacc${
          firstName && firstName !== "undefined" ? `, ${firstName}!` : "!"
        }`}
      />
      {!getCookie("login2FASecKey") && (
        <Button
          sx={{ mb: 2 }}
          variant="contained"
          color="primary"
          onClick={() => navigate("/mfa-setup")}
        >
          Configure 2FA
        </Button>
      )}
      {requestedAccess?.length > 0 && (
        <AccessRequestsDashboard
          requestedAccess={requestedAccess}
          refetchInvitations={fetchInvitations}
          fetchBusinessFiles={fetchBusinessFiles}
        />
      )}
      {invitations?.length > 0 && (
        <InvitationsDashboard
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          invitations={invitations}
          refetchInvitations={fetchInvitations}
          fetchBusinessFiles={fetchBusinessFiles}
        />
      )}
      {businessFilesOfUserData?.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: secondBreakpoint ? "column" : "row-reverse",
          }}
        >
          {favorites?.length > 0 && (
            <FavoritesDashboard
              fullwidth={secondBreakpoint}
              favorites={favorites}
              handleDbSelect={handleDbSelect}
              handleFavChanged={fetchBusinessFiles}
            />
          )}
          <BusinessFilesDashboard
            subscriberTextInfo={invitationsData?.SubscriberTextInfo}
            fullwidth={secondBreakpoint}
            files={businessFilesOfUserData}
            handleDbSelect={handleDbSelect}
            handleFavChanged={fetchBusinessFiles}
          />
        </Box>
      )}
      {!invitations?.length > 0 &&
        !requestedAccess?.length > 0 &&
        !businessFilesOfUserData?.length > 0 && <EmptyDashboard />}
    </>
  );
};

export default Dashboard;
