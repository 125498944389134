import {
  Alert,
  Box,
  Button,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import ThemeToggle from "../../ThemeToggle/ThemeToggle";
import { useTheme } from "../../../context/ThemeContext";
import { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import envConfig from "../../../config";
import { darkTheme, lightTheme } from "../../../utils/themes/themes";

const ResetPassword = () => {
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;
  const navigate = useNavigate();
  const noFullFit = useMediaQuery("(max-width: 1300px)");
  const isTablet = useMediaQuery("(max-width: 1028px)");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const recaptchaRef = useRef();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!recaptchaValue) return;
    setLoading(true);

    const formData = new FormData(e.target);
    const emailAddress = formData.get("email");

    try {
      const response = await fetch(
        `https://${envConfig.apiDev1}/api/v3/en-au/users/send-forgot-user-password-email?LoginEmail=${emailAddress}&BaseHostURL=${envConfig.loginUrl}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "olivs-api-real-ip": "115.70.37.103",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "api-key": "OlivsAuth",
            captcha: envConfig.loginUrl + ":" + recaptchaValue,
          },
        }
      );
      const data = await response.json();

      if (data?.IsEmailSent) {
        setOpenSuccess(true);
        setTimeout(() => {
          navigate("/");
        }, 5000);
      } else {
        setAlertMessage(
          "Account associated with this email could not be found."
        );
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        setOpen(true);
      }
    } catch (error) {
      setAlertMessage("An error occurred while sending the recovery email.");
      setOpen(true);
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        mx: isMobile ? "auto" : "unset",
        ml: isMobile ? "auto" : isTablet ? "48px" : noFullFit ? 10 : 16,
        px: isMobile ? 5 : "unset",
      }}
    >
      <Snackbar
        sx={{ zIndex: 9999999 }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
      <Snackbar
        sx={{ zIndex: 9999999 }}
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Success! Please check your inbox
        </Alert>
      </Snackbar>
      <Logo
        height={42}
        onClick={() => {
          navigate("/");
        }}
        style={{
          color: theme.palette.primary.main,
          cursor: "pointer",
          marginTop: "64px",
          marginBottom: "50px",
        }}
      />
      <form onSubmit={handleFormSubmit}>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            color: theme.typography.linkColor,
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Inter",
            fontSize: "28px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "133.4%",
          }}
        >
          Reset your password
        </Typography>
        <Typography
          sx={{
            maxWidth: isMobile ? "100%" : isTablet ? 380 : 480,
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "150%",
            letterSpacing: "0.15px",
            mt: 3,
          }}
        >
          Enter email address linked to your account and we will send you a
          password recovery link.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            margin="normal"
            label="Email Address"
            name="email"
            required
            inputProps={{
              autoComplete: "off",
            }}
            sx={{
              marginTop: 4,
              width: isMobile ? "100%" : isTablet ? 380 : 480,
            }}
          />
        </Box>
        <Box>
          <ReCAPTCHA
            ref={recaptchaRef}
            // sitekey="6LdYx4IpAAAAAAaLdLwBuPqrQ6SpuFsrhYiFB_nD"
            // sitekey="6Ld3tWEpAAAAAFLHNAg3dWSEuv8rCyBNt6l6meJE"
            sitekey={
              window.location.origin.includes("login")
                ? process.env.REACT_APP_SITE_KEY_LOGIN_OLIVS
                : window.location.origin.includes("olivs.app")
                ? process.env.REACT_APP_SITE_KEY_OLIVS_APP
                : window.location.origin.includes(".ssx.")
                ? process.env.REACT_APP_SITE_KEY_SSX
                : process.env.REACT_APP_SITE_KEY_MY_EXACC
            }
            onChange={handleRecaptcha}
            theme={isDarkMode ? "dark" : "light"}
          />
        </Box>
        <Box
          sx={{
            width: isMobile ? "100%" : isTablet ? 380 : 480,
            borderBottom: "1px solid #EDEDED",
            paddingBottom: "39px",
          }}
        >
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={loading}
            fullWidth
            sx={{
              marginTop: 5,
              width: isMobile ? "100%" : isTablet ? 380 : 480,
            }}
          >
            Send Recovery Email
          </Button>
        </Box>
        <Box
          sx={{
            mt: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: isMobile ? "100%" : isTablet ? 380 : 480,
          }}
        >
          <Typography sx={{ fontSize: 14 }}>Remember password?</Typography>
          <Link
            className="link"
            to={"/login"}
            style={{
              color: theme.palette.primary.main,
              marginLeft: 24,
              marginTop: 0,
              cursor: "pointer",
              fontSize: "14px",
              fontWeight: 700,
              letterSpacing: "0.4px",
              textTransform: "capitalize",
              textDecoration: "none",
            }}
          >
            Login
          </Link>
        </Box>
      </form>
      <Box
        sx={{
          position: "relative",
          width: isMobile ? "100%" : isTablet ? 380 : 480,
          my: 3,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ThemeToggle
          titlefontSize={16}
          title={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
        />
      </Box>
    </Box>
  );
};

export default ResetPassword;
