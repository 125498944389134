import React, { useEffect } from "react";
import { enqueueSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import envConfig from "../../config";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";

const BankFeedsConnected = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const returnUrl = queryParams.get("state");
  const jobId = queryParams.get("jobId");
  useEffect(() => {
    if (returnUrl) {
      window.location = returnUrl + `?jobId=${jobId}`;
    }
  }, [returnUrl]);

  return <BackdropLoading open={true} />;
};

export default BankFeedsConnected;
