import React, { createContext, useContext, useEffect } from "react";

const PixelContext = createContext();

export const PixelProvider = ({ children }) => {
  useEffect(() => {
    const pixelId = process.env.REACT_APP_FB_PIXEL_ID || "TEST_PIXEL_ID";

    // Initialize Facebook Pixel
    const initPixel = () => {
      if (window.fbq) return;
      window.fbq = function () {
        window.fbq.callMethod
          ? window.fbq.callMethod.apply(window.fbq, arguments)
          : window.fbq.queue.push(arguments);
      };
      if (!window._fbq) window._fbq = window.fbq;
      window.fbq.push = window.fbq;
      window.fbq.loaded = true;
      window.fbq.version = "2.0";
      window.fbq.queue = [];
    };

    // Load FB script
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://connect.facebook.net/en_US/fbevents.js";
    document.head.appendChild(script);

    initPixel();
    window.fbq("init", pixelId);

    return () => {
      document.head.removeChild(script);
      delete window.fbq;
    };
  }, []);

  const trackEvent = (eventName, params = {}, isCustom = false) => {
    if (window.fbq) {
      const method = isCustom ? "trackCustom" : "track";
      window.fbq(method, eventName, params);
      console.log(`${method} event:`, eventName, params);
    }
  };

  return (
    <PixelContext.Provider value={{ trackEvent }}>
      {children}
    </PixelContext.Provider>
  );
};

export const usePixel = () => {
  const context = useContext(PixelContext);
  if (!context) {
    throw new Error("usePixel must be used within a PixelProvider");
  }
  return context;
};
