import { ThemeProvider } from "./context/ThemeContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./pages/Login/Login";
import { Container, CssBaseline } from "@mui/material";
import Logout from "./components/Login/Logout/Logout";
import CreateBusinessAccount from "./pages/CreateBusinessAccount/CreateBusinessAccount";
import SessionManagement from "./components/SessionManagement/SessionManagement";
import MainNavigation from "./components/MainNavigation/MainNavigation";
import { PopupProvider } from "./context/LoginPopupContext";
import Dashboard from "./components/Dashboard/Dashboard";
import DashboardMobile from "./components/DashboardMobile/DashboardMobile";
import { AuthProvider } from "./context/CheckApiKeyContext";
import TwoFactorSetup from "./pages/MFAConfig/MFAConfig";
import ManageSubscriber from "./pages/ManageSubscriber/ManageSubscriber";
import { licenseKey } from "./devextreme-license";
import config from "devextreme/core/config";
import GlobalUserEdit from "./pages/GlobalUserEdit/GlobalUserEdit";
import InviteSubscriberPage from "./pages/InviteSubscriberPage/InviteSubscriberPage";
import BankFeedsConnected from "./pages/BankFeedsConntected/BankFeedsConntected";
import { PixelProvider } from "./context/PixelContext";

const App = () => {
  config({ licenseKey: licenseKey });

  const prefix = "/:dbId/:lang";

  return (
    <PixelProvider>
      <ThemeProvider>
        <BrowserRouter>
          <PopupProvider>
            <AuthProvider>
              <CssBaseline />
              <>
                <Routes>
                  <Route
                    path={`${prefix}/authorization/validation/:sessionGuid`}
                    element={
                      <>
                        <Container>
                          <SessionManagement />
                        </Container>
                      </>
                    }
                  />

                  <Route
                    path={"/subscriber"}
                    element={
                      <Container>
                        <MainNavigation pageCode="gl-subscriber" />
                        <ManageSubscriber />
                      </Container>
                    }
                  />

                  <Route
                    path={"/bank-feeds-connected"}
                    element={
                      <Container>
                        <MainNavigation pageCode="gl-subscriber" />
                        <BankFeedsConnected />
                      </Container>
                    }
                  />
                  <Route
                    path={"/subscriber/:id"}
                    element={
                      <Container>
                        <MainNavigation pageCode="gl-subscriber" />
                        <ManageSubscriber />
                      </Container>
                    }
                  />
                  <Route
                    path={"/user-dashboard"}
                    element={
                      <Container>
                        <MainNavigation pageCode="gl-dashboard" />
                        <Dashboard />
                      </Container>
                    }
                  />
                  <Route
                    path={"/add-member-to-subscriber/:id"}
                    element={
                      <Container>
                        <MainNavigation pageCode="lc-invite-subscriber" />
                        <InviteSubscriberPage />
                      </Container>
                    }
                  />
                  <Route
                    path={`/mfa-setup`}
                    element={
                      <Container maxWidth={1664}>
                        <MainNavigation pageCode="gl-user-2fa-setup" />
                        <TwoFactorSetup />
                      </Container>
                    }
                  />
                  <Route
                    path="/create-new-exacc-file"
                    element={
                      <>
                        <Container>
                          <MainNavigation pageCode="gl-create-new-exacc-file" />
                          <CreateBusinessAccount />
                        </Container>
                      </>
                    }
                  ></Route>
                  <Route
                    path="/create-new-subscriber"
                    element={
                      <>
                        <Container>
                          <MainNavigation pageCode="gl-create-new-subscriber" />
                          <CreateBusinessAccount isCreatingSubscriber={true} />
                        </Container>
                      </>
                    }
                  ></Route>

                  <Route
                    path="/my-details"
                    element={
                      <>
                        <Container>
                          <MainNavigation pageCode="gl-my-details" />
                          <GlobalUserEdit />
                        </Container>
                      </>
                    }
                  ></Route>

                  <Route path="/logout" element={<Logout />}></Route>
                  <Route
                    path={`*`}
                    element={
                      <>
                        <Login />
                      </>
                    }
                  />
                </Routes>
              </>
            </AuthProvider>
          </PopupProvider>
        </BrowserRouter>
      </ThemeProvider>
    </PixelProvider>
  );
};

export default App;
