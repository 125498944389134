import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import { useState } from "react";
import { Divider, Drawer, List, ListItem, ListItemText } from "@mui/material";
import { createSessionForExternal } from "../../utils/common";
import { IconButton, Box } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { ReactComponent as SwitchBusinessIcon } from "../../assets/switch-account-icon.svg";
import { ReactComponent as LogOutIcon } from "../../assets/log-out-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/add.svg";

import { useNavigate, useParams } from "react-router-dom";

import {
  FavouritesIcon,
  PayrollIcon,
  BusinessIcon,
  ReportsIcon,
  FinanceIcon,
} from "../MainNavigation/NavigationIcons";

import ThemeToggle from "../ThemeToggle/ThemeToggle";
import { getCookie } from "../../utils/api";
import envConfig from "../../config";

const MobileMenuSection = ({
  icon = {},
  handleMenuClick = () => {},
  open,
  setOpen = () => {},
  companyName = "",
  currentMenuItem = "",
  urlPrefix = "",
  //
  mainMenuTitle = "",
  sectionOne = [],
  sectionOneTitle = "",
  sectionOneExtra = [],
  //
  sectionTwo = [],
  sectionTwoTitle = "",
  sectionTwoExtra = [],
  //
  sectionThree = [],
  sectionThreeTitle = "",
  sectionThreeExtra = [],
  //
  isCompanyTile,
  sectionFour = [],
  sectionFourTitle = "",
  sectionFourExtra = [],
}) => {
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const renderMenuItems = (items, extraItems, title) => (
    <>
      {items?.length > 0 && currentMenuItem === mainMenuTitle && (
        <List sx={{ padding: 0, margin: 0 }}>
          {items.map(
            (subpage, subIndex) =>
              (companyName !== "My Profile" ||
                subpage.type === "my_profile") && (
                <div key={subIndex}>
                  {subpage.Label && subpage.type !== "quick_link" && (
                    <ListItem
                      onClick={() => {
                        if (subpage.Hyperlink) {
                          if (subpage.Hyperlink.startsWith("http")) {
                            createSessionForExternal(subpage.Hyperlink);
                            setOpen(false);
                          } else {
                            navigate(`${subpage.Hyperlink}`, { replace: true });
                            setOpen(false);
                          }
                        }
                      }}
                      sx={{
                        cursor: "pointer",
                        fontSize: 13,
                        "&:hover": {
                          color: theme.palette.primary.main,
                        },
                        userSelect: "none",
                      }}
                    >
                      <ListItemText
                        disableRipple
                        sx={{
                          span: {
                            cursor: "pointer",
                            paddingLeft: 3.3,
                            mt: -0.5,
                            mb: -0.5,
                            color: theme.palette.text.main,
                            "&:hover": {
                              color: theme.palette.primary.main,
                            },
                          },
                        }}
                        primary={subpage.Label}
                      />
                    </ListItem>
                  )}
                </div>
              )
          )}
          {extraItems?.map((extra, extraIndex) => (
            <div key={extraIndex} style={{ marginTop: "-12px" }}>
              <List
                disableRipple
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    color: theme.palette.primary.main,
                  },
                  fontSize: 14,
                  color: theme.palette.text.secondary,
                  paddingLeft: 5.3,
                  fontWeight: 500,
                  mt: 1,
                  marginBottom: 0,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (extra.Hyperlink) {
                    if (extra.Hyperlink.startsWith("http")) {
                      createSessionForExternal(extra.Hyperlink);
                      setOpen(false);
                    } else {
                      navigate(`${extra.Hyperlink}`, { replace: true });
                      setOpen(false);
                    }
                  }
                }}
                style={{ userSelect: "none" }}
              >
                {extra?.Icon === "plus" && (
                  <AddIcon
                    width={10}
                    height={10}
                    style={{
                      marginRight: "10px",
                    }}
                  />
                )}
                {extra?.Label}
              </List>
            </div>
          ))}
        </List>
      )}
    </>
  );

  return (
    <div>
      {(sectionOne.length > 0 ||
        sectionTwo.length > 0 ||
        sectionThree.length > 0 ||
        sectionFour.length > 0) && (
        <ListItem
          onClick={() => {
            handleMenuClick(mainMenuTitle);
          }}
          sx={{
            cursor: "pointer",
            userSelect: "none",
          }}
        >
          {icon && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: "24px",
                stroke: theme.palette.text.secondary,
                color: theme.palette.text.secondary,
                "&:hover": {
                  color: theme.palette.primary.main,
                  stroke: theme.palette.primary.main,
                },
              }}
            >
              <span
                style={{
                  marginRight: "18px",
                  minWidth: "30px",
                  position: "relative",
                }}
              >
                {!isCompanyTile && icon}
                {isCompanyTile && (
                  <>
                    <span
                      style={{
                        textTransform: "uppercase",
                        color: theme.palette.primary.main,
                        fontSize: "9px",
                        top: "-6px",
                        width: "max-content",
                        position: "absolute",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      {mainMenuTitle}
                    </span>
                  </>
                )}
              </span>
            </Box>
          )}

          <ListItemText
            sx={{
              span: {
                fontWeight: 500,
                color: theme.palette.text.secondary,
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              },
            }}
            primary={isCompanyTile ? "Manage" : mainMenuTitle}
          />
        </ListItem>
      )}

      {renderMenuItems(sectionOne, sectionOneExtra, sectionOneTitle)}
      {renderMenuItems(sectionTwo, sectionTwoExtra, sectionTwoTitle)}
      {renderMenuItems(sectionThree, sectionThreeExtra, sectionThreeTitle)}
      {renderMenuItems(sectionFour, sectionFourExtra, sectionFourTitle)}

      {open && currentMenuItem === mainMenuTitle && !isCompanyTile && (
        <Divider
          sx={{
            marginTop: 2,
            marginBottom: 2,
          }}
        />
      )}
    </div>
  );
};

const MobileNavigation = ({ ip, newMenuItems, urlPrefix }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [currentMenuItem, setCurrentMenuItem] = useState(null);
  const { dbId } = useParams();
  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const handleMenuToggle = () => {
    setOpen(!open);
  };

  const handleMenuClick = (label) => {
    if (label === currentMenuItem) {
      setCurrentMenuItem(null);
      return;
    }
    setCurrentMenuItem(label);
  };

  const handleSwitchBusiness = () => {
    navigate("/user-dashboard", { replace: true });
  };

  const handleLogOut = () => {
    window.location = `https://${envConfig.loginUrl}/logout?apiKey=${getCookie(
      "apiKey"
    )}-${dbId || getCookie("databaseId")}&sessionId=${getCookie("sessionId")}`;
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleMenuToggle}>
        <MenuOutlinedIcon />
      </IconButton>
      <Drawer
        sx={{ zIndex: 9999999999 }}
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
      >
        <List
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            minWidth: "320px",
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
            }}
          >
            <MobileMenuSection
              ip={ip}
              icon={
                <FavouritesIcon
                  width={24}
                  height={24}
                  style={{ marginLeft: "2px" }}
                />
              }
              handleMenuClick={handleMenuClick}
              open={open}
              setOpen={setOpen}
              companyName={newMenuItems?.BusinessCode}
              currentMenuItem={currentMenuItem}
              urlPrefix={urlPrefix}
              //
              mainMenuTitle="Favorites"
              sectionOneTitle="Favorites"
              sectionOne={newMenuItems?.Me_QuickLinks}
            />
            <MobileMenuSection
              ip={ip}
              icon={
                <PayrollIcon
                  width={24}
                  height={24}
                  style={{ marginLeft: "2px" }}
                />
              }
              handleMenuClick={handleMenuClick}
              open={open}
              setOpen={setOpen}
              companyName={newMenuItems?.BusinessCode}
              currentMenuItem={currentMenuItem}
              urlPrefix={urlPrefix}
              //
              mainMenuTitle="Payroll"
              sectionOne={newMenuItems?.Payroll_Payroll_Links}
              sectionOneTitle="Payroll"
              sectionOneExtra={newMenuItems?.Payroll_Payroll_Extras}
              sectionTwo={newMenuItems?.Payroll_Rosters_Links}
              sectionTwoTitle="Rosters"
              sectionTwoExtra={newMenuItems?.Payroll_Rosters_Extras}
              sectionThree={newMenuItems?.Payroll_Timesheet_Links}
              sectionThreeTitle="Timesheet"
              sectionThreeExtra={newMenuItems?.Payroll_Timesheet_Extras}
            />
            <MobileMenuSection
              ip={ip}
              icon={
                <BusinessIcon
                  width={23}
                  height={24}
                  style={{ marginLeft: "3px" }}
                />
              }
              handleMenuClick={handleMenuClick}
              open={open}
              setOpen={setOpen}
              companyName={newMenuItems?.BusinessCode}
              currentMenuItem={currentMenuItem}
              urlPrefix={urlPrefix}
              //
              mainMenuTitle="Business"
              sectionOne={newMenuItems?.Contacts_Links}
              sectionOneTitle="Contacts"
              sectionOneExtra={newMenuItems?.Contacts_Extras}
              sectionTwo={newMenuItems?.Invoices_Purchases_Links}
              sectionTwoTitle="Purchases"
              sectionTwoExtra={newMenuItems?.Invoices_Purchases_Extras}
              sectionThree={newMenuItems?.Invoices_Sales_Links}
              sectionThreeTitle="Sales"
              sectionThreeExtra={newMenuItems?.Invoices_Sales_Extras}
            />

            <MobileMenuSection
              ip={ip}
              icon={
                <FinanceIcon
                  style={{ marginBottom: "-3px" }}
                  width={30}
                  height={30}
                />
              }
              handleMenuClick={handleMenuClick}
              open={open}
              setOpen={setOpen}
              companyName={newMenuItems?.BusinessCode}
              currentMenuItem={currentMenuItem}
              urlPrefix={urlPrefix}
              //
              mainMenuTitle="Finance"
              sectionOneTitle="Accounting"
              sectionOne={newMenuItems?.Accounting_Links}
              sectionOneExtra={newMenuItems?.Accounting_Extras}
              sectionTwoTitle="Banking"
              sectionTwo={newMenuItems?.Accounting_Banking_Links}
              sectionTwoExtra={newMenuItems?.Accounting_Banking_Extras}
            />
            <MobileMenuSection
              ip={ip}
              icon={<ReportsIcon width={28} height={28} />}
              handleMenuClick={handleMenuClick}
              open={open}
              setOpen={setOpen}
              companyName={newMenuItems?.BusinessCode}
              currentMenuItem={currentMenuItem}
              urlPrefix={urlPrefix}
              //
              mainMenuTitle="Reports"
              sectionOne={newMenuItems?.Reports}
            />

            <MobileMenuSection
              ip={ip}
              icon={<></>}
              handleMenuClick={handleMenuClick}
              open={open}
              setOpen={setOpen}
              mainMenuTitle={newMenuItems?.BusinessCode}
              companyName={newMenuItems?.BusinessCode}
              currentMenuItem={currentMenuItem}
              urlPrefix={urlPrefix}
              isCompanyTile={true}
              sectionOne={
                newMenuItems?.Me_Configuration?.length
                  ? newMenuItems?.Me_Configuration
                  : newMenuItems.Subscribers
              }
              sectionOneTitle={
                newMenuItems?.Me_Configuration?.length
                  ? "Configuration"
                  : "Subscribers"
              }
              sectionOneExtra={newMenuItems?.Subscribers_Extra}
              sectionTwo={newMenuItems?.Me_MyProfile}
              sectionTwoTitle="My Profile"
              sectionFour={
                newMenuItems?.Me_Users?.length
                  ? newMenuItems?.Me_Users
                  : newMenuItems?.GlobalProfile
              }
              sectionFourTitle={
                newMenuItems?.Me_Users?.length ? "Users" : "My Profile"
              }
              // sectionFourExtra={[
              //   {
              //     type: "dark_mode",
              //   },
              //   {
              //     name: "Switch Business",
              //     type: "switch_business",
              //     Hyperlink: "/olivs/change-business-file",
              //     Label: "Switch business",
              //   },
              //   {
              //     name: "Log Out",
              //     type: "log_out",
              //     Hyperlink: "https://www.login.olivs.com/log-out",
              //     Label: "Log out",
              //   },
              // ]}
            />
          </Box>
          <Divider />

          <Box
            sx={{ display: "flex", ".MuiTypography-root": { display: "none" } }}
          >
            <ListItem
              onClick={() => handleMenuClick("Dark Mode")}
              sx={{
                cursor: "pointer",
                // backgroundColor: theme.navigation.menuBackgroundColor,
                color: theme.palette.text.secondary,
                label: { margin: 0 },
                justifyContent: "center",
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
            >
              <ThemeToggle titlefontSize={16} />
            </ListItem>
            <ListItem
              onClick={() => handleSwitchBusiness()}
              sx={{
                cursor: "pointer",
                width: "calc(100% - 12px)",
                // backgroundColor: theme.navigation.menuBackgroundColor,
                color: theme.palette.text.secondary,
                justifyContent: "center",
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
            >
              <SwitchBusinessIcon width={32} height={32} />
              {/* <ListItemText
                // sx={{ marginLeft: "23px" }}
                primary="Switch Business"
              /> */}
            </ListItem>
            <ListItem
              onClick={() => handleLogOut()}
              sx={{
                cursor: "pointer",
                // marginLeft: "12px",
                width: "calc(100% - 12px)",
                // backgroundColor: theme.navigation.menuBackgroundColor,
                color: theme.palette.text.secondary,
                justifyContent: "center",
                "&:hover": {
                  color: theme.palette.primary.main,
                },
              }}
            >
              <LogOutIcon width={32} height={32} />
              {/* <ListItemText sx={{ marginLeft: "23px" }} primary="Log Out" /> */}
            </ListItem>
          </Box>
        </List>
      </Drawer>
    </>
  );
};

export default MobileNavigation;
