import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { ReactComponent as SunIcon } from "../../assets/sun-icon.svg";
import { ReactComponent as MoonIcon } from "../../assets/moon-icon.svg";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import themes from "devextreme/ui/themes";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 40,
  padding: 7,
  paddingLeft: 14,
  paddingRight: 0,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      transform: "translateX(22px)",
      "& + .MuiSwitch-track": {
        backgroundColor: "#EDEDED",
        opacity: 1,
      },
    },
    "&:hover": {
      // backgroundColor: "transparent",
    },
  },
  "& .MuiSwitch-thumb": {
    width: 32,
    height: 32,
    backgroundColor: "#FFF",
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: "#8796A5",
    borderRadius: 20,
  },
}));

const ThemeToggle = ({ titleFontSize = "14px", title = "Dark Mode" }) => {
  const { isDarkMode, toggleDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  useEffect(() => {
    const themeName = isDarkMode
      ? "material.custom-scheme-dark"
      : "material.custom-scheme-light";
    try {
      themes.current(themeName);
      themes.ready(() => console.log("Theme switched:", themeName));
    } catch (error) {
      console.error("Theme switch error:", error);
    }
  }, [isDarkMode]);

  return (
    <FormControlLabel
      sx={{
        my: -1.5,
        "& .MuiFormControlLabel-label": {
          ml: 2,
          fontFamily: "Inter",
          fontWeight: 500,
          fontSize: titleFontSize,
          letterSpacing: "0.15px",
        },
        // "&:hover": {
        //   backgroundColor: "transparent",
        // },
      }}
      label={title}
      control={
        <MaterialUISwitch
          checked={!isDarkMode}
          onChange={toggleDarkMode}
          sx={{
            transition: ".1s",
            svg: {
              color: "#8796A5",
            },
            "&:hover": {
              svg: {
                color: theme.palette.primary.main,
              },
            },
          }}
          icon={
            <MoonIcon
              style={{
                width: 16,
                height: 16,
                background: "#FFF",
                borderRadius: "50%",
                position: "absolute",
                bottom: "-27px",
                right: "-28px",
              }}
            />
          }
          checkedIcon={
            <SunIcon
              style={{
                width: 16,
                height: 16,
                background: "#FFF",
                borderRadius: "50%",
                position: "absolute",
                bottom: "-27px",
                right: "-33px",
              }}
            />
          }
        />
      }
    />
  );
};

export default ThemeToggle;
