import { alpha, createTheme } from "@mui/material/styles";

export const commonThemes = createTheme({
  inputLabel: {
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  pageTitle: {
    fontFamily: "Inter",
    fontSize: "28px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: "24px",
    letterSpacing: "0.15px",
  },
  popupTitle: {
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "36px",
    letterSpacing: "-0.264px",
  },
});

export const lightTheme = createTheme({
  ...commonThemes,
  palette: {
    mode: "light",
    primary: {
      main: "#E9702C",
      light: alpha("#E9702C", 0.08),
      dark: alpha("#E9702C", 0.3),
    },
    secondary: {
      main: "#6D7B96",
      light: "#AEBFE3",
      dark: alpha("#6D7B96", 0.3),
    },
    text: {
      primary: "#252525",
      secondary: "#495570",
      tertiary: alpha("#252525", 0.6),
      disabled: alpha("#252525", 0.38),
      menuItemText: "#252525",
    },
    background: {
      default: "#F5F5F5",
      paper: "#FFF",
    },
    action: {
      hover: alpha("#000", 0.04),
      active: alpha("#E9702C", 0.3),
    },
    divider: "#EDEDED",
    blackWhite: {
      main: "#000",
    },
  },
  // Add these component overrides to both light and dark themes
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 1664,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          transition: "color 0.1s",
          a: {
            transition: "color 0.1s",
          },
          "&:hover": {
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
            a: {
              color: `${theme.palette.primary.main} !important`,
            },
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
        },
        icon: {
          fill: "rgba(31, 29, 29, 0.8)",
        },
      },
    },
    MuiDatePicker: {
      styleOverrides: {
        root: {
          backgroundColor: "#FFF",
        },
      },
      defaultProps: {
        size: "small",
        slotProps: {
          openPickerButton: {
            sx: {
              color: "rgba(255, 255, 255, 0.56)",
            },
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "sectionTitle" },
          style: ({ theme }) => ({
            color: theme.palette.text.secondary,
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
          }),
        },
        {
          props: { variant: "sectionLabel" },
          style: ({ theme }) => ({
            color: theme.palette.text.secondary,
            fontSize: "14px",
            fontWeight: 500,
          }),
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          fontFamily: "Inter",
          fontWeight: 700,
        },
        contained: ({ theme }) => ({
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: alpha(theme.palette.primary.main, 0.08),
          },
        }),
        outlined: ({ theme }) => ({
          borderWidth: "1px",
          "&:hover": {
            borderWidth: "1px",
            backgroundColor: alpha(theme.palette.primary.main, 0.04),
          },
        }),
      },
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
            color: "#FFF",
            "&:hover": {
              backgroundColor: alpha(theme.palette.primary.main, 0.92),
            },
          }),
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: ({ theme }) => ({
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
          }),
        },
        {
          props: { variant: "outlined", color: "secondary" },
          style: ({ theme }) => ({
            borderColor: theme.palette.secondary.main,
            color: theme.palette.secondary.main,
          }),
        },
        {
          props: { variant: "secondary" },
          style: ({ theme, ownerState }) => ({
            backgroundColor: alpha(theme.palette[ownerState.color].main, 0.08),
            color: theme.palette[ownerState.color].main,
            "&:hover": {
              backgroundColor: alpha(
                theme.palette[ownerState.color].main,
                0.04
              ),
            },
          }),
        },
      ],
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
          "&.Mui-checked": {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
          "&.Mui-checked": {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        popupIndicator: {
          color: "rgba(0, 0, 0, 0.8)",
          "&:hover": {
            color: "rgba(0, 0, 0, 0.8)",
            "& .MuiSvgIcon-root": {
              fill: "rgba(0, 0, 0, 0.8)",
              stroke: "none !important",
            },
          },
          "& .MuiSvgIcon-root": {
            fill: "rgba(0, 0, 0, 0.8)",
            stroke: "none !important",
          },
        },
        popupIndicatorOpen: {
          color: "rgba(0, 0, 0, 0.8)",
          "&:hover": {
            color: "rgba(0, 0, 0, 0.8)",
            ".MuiSvgIcon-root": {
              stroke: "none !important",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...commonThemes.inputLabel,
          color: "rgba(0, 0, 0, 0.6)",
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        indicator: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
          "&.Mui-selected": {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: alpha("#000", 0.08),
          color: "#252525",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        notchedOutline: {
          borderColor: alpha("#252525", 0.23),
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#FFF",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#FEFEFD",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          ".MuiSvgIcon-root": {
            fill: "rgba(31, 29, 29, 0.8)",
            stroke: "none",
            transition: "0.1s",
          },
          "&:hover": {
            backgroundColor: "transparent",
            ".MuiSvgIcon-root": {
              fill: theme.palette.primary.main,
            },
            ".MuiTypography-root, + .MuiTypography-root": {
              color: `${theme.palette.primary.main} !important`,
              transition: "color 0.1s",
            },
          },
          ".MuiTypography-root": {
            color: "#757575",
            transition: "color 0.1s",
          },
        }),
      },
    },
  },
  typography: {
    fontFamily: "Inter",
    fontSize: 14,
    h1: {
      fontSize: 24,
      fontWeight: 600,
      color: "#495570",
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,
      color: "#495570",
    },
    h3: {
      fontSize: 16,
      fontWeight: 600,
      color: "#495570",
    },
    body1: {
      fontSize: 14,
      color: "#252525",
    },
    body2: {
      fontSize: 12,
      color: "#495570",
    },
    button: {
      textTransform: "none",
    },
    linkColor: "#495570",
  },
});

export const darkTheme = createTheme({
  ...commonThemes,
  palette: {
    mode: "dark",
    primary: {
      main: "#FF9152",
      light: alpha("#FF9152", 0.08),
      dark: alpha("#FF9152", 0.3),
    },
    secondary: {
      main: "#AEBFE3",
      light: alpha("#AEBFE3", 0.8),
      dark: alpha("#AEBFE3", 0.3),
    },
    text: {
      primary: "#FFF",
      secondary: "#9C9D9E",
      tertiary: alpha("#FEFEFE", 0.6),
      disabled: alpha("#FFF", 0.38),
      menuItemText: "#9C9D9E",
    },
    background: {
      default: "#141821",
      paper: "#1B212D",
    },
    action: {
      hover: alpha("#000", 0.04),
      active: alpha("#A24511", 0.3),
    },
    divider: "#3E4550",
    blackWhite: {
      main: "#FFF",
    },
  },
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: 1664,
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "#1B212D",
          backgroundImage: "none",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: "unset",
        },
      },
    },

    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundImage: "none",
          backgroundColor: "#1B212D",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.primary,
          a: {
            transition: "color 0.1s",
          },
          "&:hover": {
            backgroundColor: "transparent",
            color: theme.palette.primary.main,
            a: {
              color: `${theme.palette.primary.main} !important`,
            },
          },
        }),
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          ".MuiDataGrid-pinnedColumns, .MuiDataGrid-pinnedColumnHeaders": {
            backgroundImage: "none !important",
          },
        },
      },
    },
    MuiDatePicker: {
      defaultProps: {
        size: "small",
        slotProps: {
          openPickerButton: {
            sx: {
              color: "rgba(255, 255, 255, 0.56)",
              "&:hover": {
                color: "#FF9152",
              },
            },
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTypography: {
      variants: [
        {
          props: { variant: "sectionTitle" },
          style: ({ theme }) => ({
            color: theme.palette.text.secondary,
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "24px",
          }),
        },
        {
          props: { variant: "sectionLabel" },
          style: ({ theme }) => ({
            color: theme.palette.text.secondary,
            fontSize: "14px",
            fontWeight: 500,
          }),
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          textTransform: "none",
          fontFamily: "Inter",
          fontWeight: 700,
        },
        containedPrimary: {
          color: "#252525 !important",
        },
        contained: ({ theme }) => ({
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: alpha(theme.palette.primary.dark),
          },
        }),
        outlined: ({ theme }) => ({
          borderWidth: "1px",
          "&:hover": {
            borderWidth: "1px",
            backgroundColor: alpha(theme.palette.primary.main, 0.04),
          },
        }),
      },
      variants: [
        {
          props: { variant: "contained", color: "primary" },
          style: ({ theme }) => ({
            backgroundColor: theme.palette.primary.main,
            color: "#FFF",
            "&:hover": {
              backgroundColor: alpha(theme.palette.primary.dark),
            },
          }),
        },
        {
          props: { variant: "outlined", color: "primary" },
          style: ({ theme }) => ({
            borderColor: alpha(theme.palette.primary.main, 0.8),
            color: theme.palette.primary.main,
          }),
        },
        {
          props: { variant: "outlined", color: "secondary" },
          style: ({ theme }) => ({
            borderColor: alpha(theme.palette.secondary.main, 0.8),
            color: theme.palette.secondary.main,
          }),
        },
        {
          props: { variant: "secondary" },
          style: ({ theme, ownerState }) => ({
            backgroundColor: alpha(theme.palette[ownerState.color].main, 0.08),
            color: theme.palette[ownerState.color].main,
            "&:hover": {
              backgroundColor: alpha(
                theme.palette[ownerState.color].main,
                0.04
              ),
            },
          }),
        },
      ],
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
          "&.Mui-checked": {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiRadio: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.primary.main,
          "&.Mui-checked": {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "rgba(255, 255, 255, 0.56)",
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        popupIndicator: {
          color: "rgba(255, 255, 255, 0.56)",
          "&:hover": {
            color: "rgba(255, 255, 255, 0.56)",
            "& .MuiSvgIcon-root": {
              fill: "rgba(255, 255, 255, 0.56)",
              stroke: "none !important",
            },
          },
          "& .MuiSvgIcon-root": {
            fill: "rgba(255, 255, 255, 0.56)",
            stroke: "none !important",
          },
        },
        popupIndicatorOpen: {
          color: "rgba(255, 255, 255, 0.56)",
          "&:hover": {
            color: "rgba(255, 255, 255, 0.56)",
            ".MuiSvgIcon-root": {
              stroke: "none !important",
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...commonThemes.inputLabel,
          color: "#FFF",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          ".MuiSvgIcon-root": {
            fill: "rgba(255, 255, 255, 0.56)",
            stroke: "none",
            transition: "0.1s",
          },
          "&:hover": {
            backgroundColor: "transparent",
            ".MuiSvgIcon-root": {
              fill: theme.palette.primary.main,
            },
            ".MuiTypography-root, + .MuiTypography-root": {
              color: `${theme.palette.primary.main} !important`,
              transition: "color 0.1s",
            },
          },
          ".MuiTypography-root": {
            // color: "#757575",
            transition: "color 0.1s",
          },

          svg: {
            stroke: "rgba(255, 255, 255, 0.87)",
            path: {
              transition: "stroke 0.1s",
            },
          },
          ".MuiTypography-root": {
            color: "rgba(255, 255, 255, 0.87)",
            transition: "color 0.1s",
          },
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: "none",
        },
        indicator: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.palette.text.secondary,
          "&.Mui-selected": {
            color: theme.palette.primary.main,
          },
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: alpha("#6D7B96", 0.16),
          color: "#FFF",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
        },
        notchedOutline: {
          borderColor: "#FFF",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          backgroundColor: "#1B212D",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: "#141821",
        },
      },
    },
  },
  typography: {
    fontFamily: "Inter",
    fontSize: 14,
    h1: {
      fontSize: 24,
      fontWeight: 600,
      color: "#495670",
    },
    h2: {
      fontSize: 20,
      fontWeight: 600,
      color: "#495670",
    },
    h3: {
      fontSize: 16,
      fontWeight: 600,
      color: "#495670",
    },
    body1: {
      fontSize: 14,
      color: "#FFF",
    },
    body2: {
      fontSize: 12,
      color: "#9C9D9E",
    },
    button: {
      textTransform: "none",
    },
    linkColor: "#7D8CAA",
  },
});
