import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Button,
  Grid,
  Paper,
  Box,
  Divider,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import axios from "axios";
import { getCookie } from "../../utils/api";
import envConfig from "../../config";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";

const GlobalUserEdit = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [formState, setFormState] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchUserData();
  }, []);

  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "olivs-api-real-ip": "31.42.0.186",
    "olivs-api-computer-name": "BTM-WS2019",
    "session-id": getCookie("sessionId"),
    "browser-agent": window.navigator.userAgent,
    "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!", // Replace with appropriate method to handle passwords
    "api-key": getCookie("apiKey") + "-0",
  };

  const fetchUserData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://${envConfig.apiDev1Exacc}/api/v1/en-au/global-users/get-current-global-user`,
        { headers }
      );
      if (response.data.SuccessYN === "Y") {
        setUserData(response.data.GlobalUser);
        setFormState({
          xxuFirstName: response.data.GlobalUser.xxuFirstName,
          xxuLastName: response.data.GlobalUser.xxuLastName,
          xxuNickName: response.data.GlobalUser.xxuNickName,
          xxuPhone: response.data.GlobalUser.xxuPhone,
          xxuSMTPEmail: response.data.GlobalUser.xxuSMTPEmail,
          xxuSMTPPass: response.data.GlobalUser.xxuSMTPPass,
          xxuSMTPHost: response.data.GlobalUser.xxuSMTPHost,
          xxuSMTPPort: response.data.GlobalUser.xxuSMTPPort,
          xxuSMTPSSLEnabled: response.data.GlobalUser.xxuSMTPSSLEnabled === "Y",
          xxuNotes: response.data.GlobalUser.xxuNotes,
          xxuInterfacePreference:
            response.data.GlobalUser.xxuInterfacePreference,
        });
      } else {
        enqueueSnackbar(response.data.ErrorMessage, { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching user data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (field) => (event) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]:
        field === "xxuSMTPSSLEnabled"
          ? event.target.checked
          : event.target.value,
    }));
    setIsChanged(true);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      const payload = {
        xxuFirstName: formState.xxuFirstName,
        xxuLastName: formState.xxuLastName,
        xxuNickName: formState.xxuNickName,
        xxuPhone: formState.xxuPhone,
        xxuSMTPEmail: formState.xxuSMTPEmail,
        xxuSMTPPass: formState.xxuSMTPPass,
        xxuSMTPHost: formState.xxuSMTPHost,
        xxuSMTPPort: formState.xxuSMTPPort,
        xxuSMTPSSLEnabled: formState.xxuSMTPSSLEnabled ? "Y" : "N",
        xxuNotes: formState.xxuNotes,
        xxuInterfacePreference: formState.xxuInterfacePreference,
      };

      const response = await axios.post(
        `https://${envConfig.apiDev1Exacc}/api/v1/en-au/global-users/save-global-user`,
        payload,
        { headers }
      );

      if (response.data.SuccessYN === "Y") {
        setUserData(response.data.GlobalUser);
        setIsChanged(false);
      } else {
        enqueueSnackbar(response.data.ErrorMessage, { variant: "error" });
      }
    } catch (error) {
      console.error("Error saving user data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/user-dashboard");
  };

  if (!userData) {
    return <BackdropLoading open={true} />;
  }

  const isPhoneVerified = userData.xxuMobPhoneNoVerifyYN === "Y";
  const createdOnDate = new Date(userData.xxuCreatedDateTime).toLocaleString();

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Edit User Profile - {userData.xxuFirstName} {userData.xxuLastName}
      </Typography>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={!isChanged || loading}
        >
          {loading ? <CircularProgress /> : "Save"}
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleCancel}
          sx={{ mx: 1 }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => navigate("/mfa-setup")}
          variant="contained"
          color="primary"
        >
          User 2FA
        </Button>
      </Box>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Personal Details
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="First Name"
              value={formState.xxuFirstName}
              sx={{}}
              onChange={handleChange("xxuFirstName")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Last Name"
              value={formState.xxuLastName}
              sx={{}}
              onChange={handleChange("xxuLastName")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Nick Name"
              sx={{}}
              value={formState.xxuNickName}
              onChange={handleChange("xxuNickName")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              sx={{}}
              label="Mobile Phone Number"
              value={formState.xxuPhone}
              onChange={handleChange("xxuPhone")}
              InputProps={{
                endAdornment: (
                  <Typography
                    variant="caption"
                    sx={{
                      color: isPhoneVerified ? green[500] : red[500],
                      ml: 1,
                    }}
                  >
                    {isPhoneVerified ? "Verified" : "Not verified"}
                  </Typography>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Interface Preference:</Typography>
            <RadioGroup
              row
              value={formState.xxuInterfacePreference}
              onChange={handleChange("xxuInterfacePreference")}
            >
              <FormControlLabel
                value="RIGHT"
                control={<Radio />}
                label="Right-handed"
              />
              <FormControlLabel
                value="LEFT"
                control={<Radio />}
                label="Left-handed"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Note"
              multiline
              rows={4}
              sx={{}}
              value={formState.xxuNotes}
              onChange={handleChange("xxuNotes")}
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h6" gutterBottom>
          Email Details
        </Typography>
        <Divider sx={{ mb: 2 }} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="SMTP Server"
              sx={{}}
              value={formState.xxuSMTPHost}
              onChange={handleChange("xxuSMTPHost")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="SMTP Port"
              type="number"
              value={formState.xxuSMTPPort}
              onChange={handleChange("xxuSMTPPort")}
              sx={{
                "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                  {
                    WebkitAppearance: "none",
                    margin: 0,
                  },
                "input[type=number]": {
                  MozAppearance: "textfield",
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="SMTP Username"
              value={formState.xxuSMTPEmail}
              sx={{}}
              onChange={handleChange("xxuSMTPEmail")}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="SMTP Password"
              sx={{}}
              type="password"
              value={formState.xxuSMTPPass}
              onChange={handleChange("xxuSMTPPass")}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formState.xxuSMTPSSLEnabled}
                  onChange={handleChange("xxuSMTPSSLEnabled")}
                />
              }
              label="SSL Enabled"
            />
          </Grid>
          <Grid item xs={12}>
            <Button disabled variant="contained" color="primary">
              Send Test Email
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Box mt={3}>
        <Typography variant="body2">Created on {createdOnDate}</Typography>
      </Box>
    </Container>
  );
};

export default GlobalUserEdit;
