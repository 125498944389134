import {
  Box,
  Card,
  Grid,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";

import { ReactComponent as ContactsIcon } from "../../assets/contacts-menu-icon.svg";
import { ReactComponent as ReportsIcon } from "../../assets/reports-menu-icon.svg";
import { ReactComponent as CreateInvoiceIcon } from "../../assets/create-invoice.svg";
import { ReactComponent as TimesheetIcon } from "../../assets/timesheet.svg";
import { ReactComponent as BankFeedsIcon } from "../../assets/bank-feeds.svg";
import { ReactComponent as InvoicesListIcon } from "../../assets/invoices-list.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "../../context/ThemeContext";
import { darkTheme, lightTheme } from "../../utils/themes/themes";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import axios from "axios";
import numeral from "numeral";
import { useLoginPopup } from "../../context/LoginPopupContext";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import { createSessionForExternal } from "../../utils/common";
import { getCookie } from "../../utils/api";
import { PageTitle } from "../../pages/CreateBusinessAccount/CreateBusinessAccount";
import envConfig from "../../config";

const getClickable = (dbiD) => {
  return [
    {
      label: "Create Invoice",
      icon: <CreateInvoiceIcon />,
      url: `https://${envConfig.correspondingService}/${dbiD}/en-au/invoice/standard`,
    },
    {
      label: "Timesheet",
      icon: <TimesheetIcon />,
      url: `https://${envConfig.correspondingService}/${dbiD}/en-au/timesheet/list`,
    },
    { label: "Bank Feeds", icon: <BankFeedsIcon />, url: "#" },
    {
      label: "Invoices list",
      icon: <InvoicesListIcon />,
      url: `https://${envConfig.correspondingService}/${dbiD}/en-au/invoice/list`,
    },
    {
      label: "Contacts list",
      icon: <ContactsIcon />,
      url: `https://${envConfig.correspondingService}/${dbiD}/en-au/contacts/list`,
    },
    {
      label: "Reports",
      icon: <ReportsIcon />,
      url: `https://${envConfig.correspondingService}/${dbiD}/en-au/reports/dashboard`,
    },
  ];
};

const DashboardMobile = () => {
  const databaseIdFromCookies = getCookie("databaseId");
  const { dbId, lang } = useParams();
  const urlPrefix = `/${dbId || databaseIdFromCookies}/${lang || "en-au"}`;

  const navigate = useNavigate();

  const { isDarkMode } = useTheme();
  const theme = isDarkMode ? darkTheme : lightTheme;

  const [data, setData] = useState([]);

  const [userFirstName, setUserFirstName] = useState("");
  const [clickable, setClickable] = useState([]);
  const [loading, setLoading] = useState(true);

  const { enqueueSnackbar } = useSnackbar();
  const { handleOpen } = useLoginPopup();

  const fetchData = async () => {
    setLoading(true);
    let key = getCookie("apiKey");
    const LinkA = localStorage.getItem("savedReferrer");
    const indexOfCom = LinkA.indexOf(".com");
    const indexOfAu = LinkA.indexOf(".au");

    const substringEndIndex =
      indexOfCom !== -1
        ? indexOfCom + 4
        : indexOfAu !== -1
        ? indexOfAu + 3
        : LinkA.length;

    const finalLinkA = LinkA.substring(0, substringEndIndex);
    try {
      const response = await axios.post(
        `https://${envConfig.apiDev2}/api/en-au/front-end/get-mobile-dashboard-data?BaseHostURL=${envConfig.loginUrl}`,
        {
          LinkA:
            !LinkA ||
            LinkA == "undefined" ||
            LinkA?.includes(`${envConfig.loginUrl}`)
              ? `https://${envConfig.correspondingService}`
              : finalLinkA,
          LinkS: "",
          Lang: "en-au",
          LinkMy: window.location.origin.includes(".app")
            ? "my.olivs.app"
            : window.location.origin.includes("my.exacc.au")
            ? "my.exacc.au"
            : "login.olivs.com",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "olivs-api-real-ip": "127.0.0.2",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "session-id": getCookie("sessionId"),
            "browser-agent": window.navigator.userAgent,
            "api-key": key + "-" + getCookie("databaseId"),
          },
        }
      );

      const responseData = response.data;
      if (responseData.SuccessYN === "Y") {
        setLoading(false);
        setData(responseData.Data);
        setUserFirstName(responseData.UserFirstName);
      } else {
        setLoading(false);
        handleOpen();
        enqueueSnackbar(responseData.ErrorMessage || "Unknown error occurred", {
          variant: "error",
        });
      }
    } catch (error) {
      setLoading(false);
      handleOpen();
      enqueueSnackbar("Error occurred while fetching data", {
        variant: "error",
      });
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setClickable(getClickable(getCookie("databaseId")));
  }, [getCookie("databaseId")]);

  return (
    <Box sx={{ mb: 8 }}>
      <BackdropLoading open={loading} />
      <Box sx={{ mb: 2 }}>
        <PageTitle title={userFirstName ? `Hi, ${userFirstName}!` : "Hi!"} />
        {data?.BusinessName && (
          <Typography sx={{ fontWeight: 600, color: "darkgray" }}>
            {data.BusinessName}
          </Typography>
        )}
      </Box>
      <Grid container alignItems="baseline" spacing={2}>
        {clickable.map(({ label, icon, url }, index) => (
          <Grid item xs={4} key={index}>
            <Card
              onClick={() => {
                if (url.startsWith("http")) {
                  createSessionForExternal(url);
                } else {
                  navigate(`${urlPrefix}${url}`);
                }
              }}
              sx={{
                textAlign: "center",
                borderRadius: "8px",
                boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.1)",
                padding: "15px 5px",
                cursor: "pointer",
                height: "100%",
                color: theme.typography.linkColor,
                fill: theme.palette.text.main,
                "&:hover": {
                  color: theme.palette.primary.main,
                  fill: theme.palette.primary.main,
                  path: {
                    fill: theme.navigation.hoverMenuItemColor,
                  },
                },
              }}
            >
              <Box
                sx={{
                  color: theme.palette.primary.main,
                  "svg path": {
                    fill: isDarkMode ? "#fff" : "#818595",
                  },
                }}
              >
                {icon}
              </Box>
              <Box
                sx={{
                  margin: "0px",
                  fontSize: "12px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  letterSpacing: "0.15px",
                }}
              >
                {label}
              </Box>
            </Card>
          </Grid>
        ))}

        {data?.length > 0 && (
          <Box
            sx={{
              width: "100%",
              borderRadius: 1,
              margin: "20px 0 20px 15px",
              backgroundColor: theme.palette.blackWhite,
              boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.1)",
              padding: "20px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  mb: "20px",
                  color: theme.typography.linkColor,
                  fontSize: "24px",
                  fontWeight: 400,
                  letterSpacing: "-0.264px",
                }}
              >
                Business summary
              </Typography>
            </Box>
            <Box sx={{ width: "100%" }}>
              {data?.map(({ Label, Value, Url }, index) => (
                <Box
                  sx={{
                    cursor: "pointer",
                    py: "6px",
                    my: 0,
                    px: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    "&:hover": {
                      backgroundColor: isDarkMode ? "#1E1E1E" : "#f4f4f4",
                    },
                  }}
                  key={index}
                  onClick={() => {
                    if (Url.startsWith("https")) {
                      createSessionForExternal(Url);
                    } else {
                      navigate(`${Url}`);
                    }
                  }}
                >
                  <Typography>{Label}</Typography>
                  <Typography sx={{ fontWeight: 600 }}>
                    {Value >= 0
                      ? `$${numeral(Math.abs(Value)).format("0,0.00")}`
                      : `($${numeral(Math.abs(Value)).format("0,0.00")})`}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default DashboardMobile;
