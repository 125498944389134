import React, { useCallback, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import DataGrid, {
  Column,
  Paging,
  Scrolling,
  SearchPanel,
  FilterRow,
  HeaderFilter,
} from "devextreme-react/data-grid";
import { useSnackbar } from "notistack";
import envConfig from "../../config";
import { getCookie, getHeaders } from "../../utils/api";
import CustomTabs from "../../components/Tabs/Tabs";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate, useParams } from "react-router-dom";
import useApiCall from "../../hooks/ApiCall";
import BackdropLoading from "../../components/BackdropLoading/BackdropLoading";
import ManageSubscriberBusinessFileTab from "./ManageSubscriberBusinessFileTab";
import LinkSubscriberToATO from "./LinkToATO";
import SubscriberDetails from "./SubscriberDetails";
import InviteSubscriberPage from "../InviteSubscriberPage/InviteSubscriberDialog";

const ManageSubscriber = () => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTab, setSelectedTab] = useState(0);
  const [membersData, setMembersData] = useState([]);
  const [selectedSubscriber, setSelectedSubscriber] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [fullSubscriberDetails, setFullSubscriberDetails] = useState({});
  const [open, setOpen] = useState(false);

  const [menuPosition, setMenuPosition] = useState(null);
  const [selectedMember, setSelectedMember] = useState(null);

  const {
    data: subscribersData,
    error: subscribersError,
    loading: subscribersLoading,
  } = useApiCall({
    url: `https://${
      envConfig.apiDev1
    }/api/v1/en-au/subscribers/get-subscribers-list?LoginEmail=${getCookie(
      "loginEmail"
    )}&BaseHostURL=${envConfig.loginUrl}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "olivs-api-real-ip": "31.42.0.186",
      "olivs-api-computer-name": "BTM-WS2019",
      "session-id": getCookie("sessionId"),
      "browser-agent": window.navigator.userAgent,
      "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
      "api-key": getCookie("apiKey"),
    },
    navigate,
  });

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleActionMenuOpen = (event, member) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setMenuPosition({
      top: rect.bottom,
      left: rect.left,
    });
    setSelectedMember(member);
  };

  const handleActionMenuClose = () => {
    setMenuPosition(null);
    setSelectedMember(null);
  };

  const fetchMembers = useCallback(async () => {
    if (!selectedSubscriber?.xxsID) return;
    try {
      const response = await fetch(
        `https://${envConfig.apiDev1}/api/v3/en-au/subscribers/get-list-of-subscribers-members?subscriberId=${selectedSubscriber?.xxsID}&BaseHostURL=${envConfig.apiLogin}`,
        {
          method: "GET",
          headers: getHeaders(),
        }
      );

      const data = await response.json();
      if (data.SuccessYN === "Y") {
        setMembersData(data.ListOfMembers || []);
      } else {
        enqueueSnackbar(data.ErrorMessage || "Failed to fetch data", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("Error fetching members data", {
        variant: "error",
      });
    }
  }, [enqueueSnackbar, selectedSubscriber?.xxsID]);

  const fetchSubscriberDetails = useCallback(async () => {
    console.log(selectedSubscriber?.xxsID);
    if (!selectedSubscriber?.xxsID) return;
    setLoadingDetails(true);
    try {
      const response = await fetch(
        `https://${envConfig.apiDev1}/api/v3/en-au/subscribers/get-subscriber-details?subscriberId=${selectedSubscriber?.xxsID}&BaseHostURL=${envConfig.apiLogin}`,
        {
          method: "GET",
          headers: getHeaders(),
        }
      );

      const data = await response.json();
      if (data.SuccessYN === "Y") {
        setFullSubscriberDetails(data.SubscriberDetails || {});
        setLoadingDetails(false);
      } else {
        enqueueSnackbar(data.ErrorMessage || "Failed to fetch data", {
          variant: "error",
        });
        setLoadingDetails(false);
      }
    } catch (error) {
      enqueueSnackbar("Error fetching subscriber details", {
        variant: "error",
      });
      setLoadingDetails(false);
    }
  }, [selectedSubscriber?.xxsID, enqueueSnackbar]);

  useEffect(() => {
    fetchSubscriberDetails();
  }, [fetchSubscriberDetails, selectedSubscriber]);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  const handleActivateDeactivate = async (newStatus) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://${envConfig.apiDev1}/api/v3/en-au/subscribers/change-subscriber-membership-status?BaseHostURL=${envConfig.loginUrl}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...getHeaders(),
          },
          body: JSON.stringify({
            SubscriberID: selectedSubscriber?.xxsID,
            SubscriberUserID: Number(getCookie("globalUserId")),
            ActiveYN: newStatus,
          }),
        }
      );

      const data = await response.json();

      if (data.SuccessYN === "Y") {
        enqueueSnackbar("Status updated successfully", {
          variant: "success",
        });
        await fetchMembers();
      } else {
        enqueueSnackbar(
          data.ErrorMessage || "Error occurred while processing the request",
          {
            variant: "error",
          }
        );
      }
    } catch (error) {
      enqueueSnackbar(
        error || "Network error or failed to process the request",
        {
          variant: "error",
        }
      );
      console.error("API request failed:", error);
    } finally {
      setLoading(false);
    }
    handleActionMenuClose();
  };

  const handleLodgeSTPToggle = async (newLodge, canAddBF) => {
    setLoading(true);
    try {
      const response = await fetch(
        `https://${envConfig.apiDev1}/api/v3/en-au/subscribers/change-subscriber-membership-settings?BaseHostURL=${envConfig.loginUrl}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            ...getHeaders(),
          },
          body: JSON.stringify({
            SubscriberID: selectedSubscriber?.xxsID,
            SubscriberUserID: Number(getCookie("globalUserId")),
            CanAddBusinessFilesYN: canAddBF,
            CanLodgeSTPYN: newLodge,
          }),
        }
      );

      const data = await response.json();

      if (data.SuccessYN === "Y") {
        enqueueSnackbar("Status updated successfully", {
          variant: "success",
        });
        await fetchMembers();
      } else {
        enqueueSnackbar(
          data.ErrorMessage || "Error occurred while processing the request",
          {
            variant: "error",
          }
        );
      }
    } catch (error) {
      enqueueSnackbar(
        error || "Network error or failed to process the request",
        {
          variant: "error",
        }
      );
      console.error("API request failed:", error);
    } finally {
      setLoading(false);
    }
    handleActionMenuClose();
  };

  useEffect(() => {
    console.log(id);
    console.log(subscribersData?.length > 0);
    if (id && subscribersData && subscribersData.length > 0) {
      console.log(subscribersData);
      const subscriberFromUrl = subscribersData.find(
        (subscriber) => subscriber.xxsID == id
      );
      console.log(subscriberFromUrl);
      if (subscriberFromUrl) {
        setSelectedSubscriber(subscriberFromUrl);
      }
    }
  }, [id, subscribersData]);

  useEffect(() => {
    const assignSsidToSubscriber = async () => {
      if (!fullSubscriberDetails.xxsID || fullSubscriberDetails.xxsStpSsid)
        return;

      setLoadingDetails(true);
      try {
        const response = await fetch(
          `https://${envConfig.apiDev2}/api/en-au/payroll-au/assign-ssid-to-subscriber?BaseHostURL=${envConfig.loginUrl}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              ...getHeaders(),
            },
            body: JSON.stringify({
              SubscriberID: selectedSubscriber?.xxsID,
            }),
          }
        );

        const data = await response.json();

        if (data.SuccessYN === "Y") {
          fetchSubscriberDetails(); // Re-fetch subscriber details
        } else {
          enqueueSnackbar(
            data.ErrorMessage || "Couldn't assign SSID to selected subscriber",
            {
              variant: "error",
            }
          );
        }
      } catch (error) {
        enqueueSnackbar("Couldn't assign SSID to selected subscriber", {
          variant: "error",
        });
        console.error("API request failed:", error);
      } finally {
        setLoadingDetails(false);
      }
    };

    assignSsidToSubscriber(); // Call the async function
  }, [
    fullSubscriberDetails,
    selectedSubscriber,
    enqueueSnackbar,
    fetchSubscriberDetails,
  ]);

  // Define the search expression to limit search to specific fields
  const searchExpr = ["xxrUser_xxuID", "xxrFirstName", "xxrLastName"];

  return (
    <Box p={2}>
      <InviteSubscriberPage open={open} onClose={() => setOpen(false)} />
      <BackdropLoading open={loading || subscribersLoading} />
      <Typography
        onClick={() => navigate("/create-new-subscriber")}
        variant="h4"
        gutterBottom
        style={{ cursor: "pointer" }}
      >
        Subscriber Account
      </Typography>

      <Autocomplete
        sx={{
          mt: 3,
          maxWidth: "480px",
        }}
        autoSelect={true}
        autoHighlight={true}
        options={subscribersData || []}
        getOptionLabel={(option) => option?.xxsName || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select Subscriber Account"
            variant="outlined"
            fullWidth
          />
        )}
        value={selectedSubscriber}
        onChange={(event, value) => {
          setSelectedSubscriber(value);
          if (value && value.xxsID) {
            navigate(`/subscriber/${value.xxsID}`);
          } else {
            navigate("/subscriber");
          }
        }}
      />

      {selectedSubscriber?.xxsID && (
        <CustomTabs
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
        />
      )}
      {/* Content of Selected Tab */}
      {selectedTab === 0 && (
        <Box
          p={4}
          sx={{
            boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
          }}
        >
          <>
            <Button
              variant="contained"
              color="primary"
              sx={{ mb: 2 }}
              onClick={() => {
                // setOpen(true);
                navigate(`/add-member-to-subscriber/${id}`);
              }}
            >
              + New Member
            </Button>

            <DataGrid
              dataSource={membersData}
              showBorders={true}
              wordWrapEnabled={true}
              keyExpr="xxrUser_xxuID"
              searchExpr={searchExpr}
              // Enable sorting on all columns by default
              allowColumnReordering={true}
              allowColumnResizing={true}
            >
              <Scrolling mode="virtual" />
              <Paging defaultPageSize={10} />
              <SearchPanel
                visible={true}
                width={240}
                placeholder="Search by ID, First Name, Last Name"
              />
              {/* Enable Filter Row */}
              <HeaderFilter visible={true} />
              {/* First column: Actions */}
              <Column
                caption="Actions"
                width={90}
                allowFiltering={false}
                allowSorting={false}
                alignment="center"
                cellRender={({ data }) => (
                  <IconButton
                    onClick={(event) => handleActionMenuOpen(event, data)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                )}
              />
              <Column
                allowSorting={false}
                allowFiltering={true}
                allowHeaderFiltering={false}
                dataField="xxrUser_xxuID"
                caption="User ID"
              />
              {/* <Column dataField="xxrUserLoginEmail" caption="Login Email" /> */}
              <Column
                allowFiltering={true}
                allowHeaderFiltering={false}
                dataField="xxrFirstName"
                caption="First Name"
              />
              <Column
                allowFiltering={true}
                allowHeaderFiltering={false}
                dataField="xxrLastName"
                caption="Last Name"
              />
              <Column
                dataField="xxrStatus"
                caption="Status"
                calculateDisplayValue={(rowData) =>
                  rowData.xxrStatus === "A" ? "Active" : "Inactive"
                }
                allowHeaderFiltering={true}
                allowFiltering={false}
                headerFilter={{
                  dataSource: [
                    { text: "Active", value: "A" },
                    { text: "Inactive", value: "I" },
                  ],
                }}
              />
              <Column
                dataField="xxrCanAddBFs"
                caption="Add BF"
                calculateDisplayValue={(rowData) =>
                  rowData.xxrCanAddBFs === "Y" ? "Yes" : "No"
                }
                allowFiltering={false}
                allowHeaderFiltering={true}
                headerFilter={{
                  dataSource: [
                    { text: "Yes", value: "Y" },
                    { text: "No", value: "N" },
                  ],
                }}
              />
              <Column
                dataField="xxrCanLodgeSTP"
                caption="Lodge STP"
                calculateDisplayValue={(rowData) =>
                  rowData.xxrCanLodgeSTP === "Y" ? "Yes" : "No"
                }
                allowFiltering={false}
                allowHeaderFiltering={true}
                headerFilter={{
                  dataSource: [
                    { text: "Yes", value: "Y" },
                    { text: "No", value: "N" },
                  ],
                }}
              />
              {/* <Column
                  dataField="xxlIsAdmin"
                  caption="Type"
                  calculateDisplayValue={(rowData) =>
                    rowData.xxlIsAdmin === "Y" ? "Admin" : "User"
                  }
                /> */}
            </DataGrid>
          </>
        </Box>
      )}

      {selectedSubscriber?.xxsID && selectedMember && selectedTab === 0 && (
        <Menu
          open={Boolean(menuPosition)}
          onClose={handleActionMenuClose}
          anchorEl={menuPosition ? document.body : null} // anchorEl to bind to the element
          anchorReference="anchorPosition"
          anchorPosition={
            menuPosition
              ? { top: menuPosition.top, left: menuPosition.left }
              : undefined
          }
        >
          <MenuItem
            onClick={() =>
              handleActivateDeactivate(
                selectedMember?.xxrStatus === "A" ? "N" : "Y"
              )
            }
          >
            {selectedMember?.xxrStatus === "A" ? "Deactivate" : "Activate"}
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleLodgeSTPToggle(
                selectedMember?.xxrCanLodgeSTP,
                selectedMember?.xxrCanAddBFs === "Y" ? "N" : "Y"
              )
            }
          >
            {selectedMember?.xxrCanAddBFs === "Y"
              ? "Disable Adding Business Files"
              : "Enable Adding Business Files"}
          </MenuItem>
          <MenuItem
            onClick={() =>
              handleLodgeSTPToggle(
                selectedMember?.xxrCanLodgeSTP === "Y" ? "N" : "Y",
                selectedMember?.xxrCanAddBFs
              )
            }
          >
            {selectedMember?.xxrCanLodgeSTP === "Y"
              ? "Disable Lodging STP as Tax Agent"
              : "Enable Lodging STP as Tax Agent"}
          </MenuItem>
        </Menu>
      )}

      {/* Other Tabs Content */}
      {selectedSubscriber?.xxsID && selectedTab === 1 && (
        <Box
          p={4}
          sx={{
            boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
          }}
        >
          <ManageSubscriberBusinessFileTab
            selectedSubscriber={selectedSubscriber}
          />
        </Box>
      )}
      {selectedSubscriber?.xxsID && selectedTab === 2 && (
        <Box
          p={4}
          sx={{
            boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
          }}
        >
          <LinkSubscriberToATO
            selectedSubscriber={selectedSubscriber}
            subscriberDetails={fullSubscriberDetails}
            loading={loadingDetails}
            fetchSubscriberDetails={fetchSubscriberDetails}
          />
        </Box>
      )}
      {selectedSubscriber?.xxsID && selectedTab === 3 && (
        <Box
          p={4}
          sx={{
            boxShadow: "0px 0px 10px 0px rgba(69, 90, 100, 0.10)",
          }}
        >
          <SubscriberDetails
            selectedSubscriber={selectedSubscriber}
            fullSubscriberDetails={fullSubscriberDetails}
            loading={loadingDetails}
            fetchSubscriberDetails={fetchSubscriberDetails}
          />
        </Box>
      )}
    </Box>
  );
};

export default ManageSubscriber;
