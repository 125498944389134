import React, { createContext, useState, useEffect } from "react";
import { getCookie } from "../utils/api";
import { useSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoginPopup } from "./LoginPopupContext";
import envConfig from "../config";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const { handleOpen } = useLoginPopup();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();

  const checkAuth = () => {
    const path = location.pathname;
    const storedTime = getCookie("apiKeyExpirationUTCDate");
    if (!storedTime) {
      window.location = `https://${envConfig.loginUrl}/`;
      return;
    }

    const currentTimeUTC = new Date().toUTCString();
    const storedTimeUTC = new Date(storedTime + "Z").toUTCString();
    const difference =
      new Date(storedTimeUTC).getTime() - new Date(currentTimeUTC).getTime();

    // console.log(difference / 60 / 1000);
    const maxDiff = envConfig.loginUrl.includes(".app") ? 1 : 5;
    if (difference > maxDiff * 60 * 1000) return;
    if (
      difference <= maxDiff * 60 * 1000 &&
      getCookie("apiKey") &&
      getCookie("apiKey") !== "undefined"
    ) {
      if (path === "/mfa") {
        enqueueSnackbar("Session expired, please log in again.", {
          variant: "info",
        });
        navigate("/");
        return;
      }
      handleOpen();
    } else {
      console.log(difference);
      window.location = `https://${envConfig.loginUrl}/`;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const path = location.pathname;

      if (
        path === "" ||
        path === "/" ||
        path === "/register" ||
        path === "/verify-email" ||
        path === "/reset-password" ||
        path.includes("bank-feeds-connected")
        // path === "/verify-phone" ||
        // path === "/mfa" ||
        // path.includes("verify") ||
        // path.includes("forgotuserpassword")
      ) {
        return;
      }

      checkAuth();
    }, 5000);

    return () => clearInterval(interval);
  }, [location]);

  return <AuthContext.Provider>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };
